import React, { useState, useCallback } from "react";
import { BetSettlementMainHeader, csvInfo } from "../CyrusUI/CyrusUiCommon";
import Tooltips from "../Common/Tooltip";
import {
    Currency,
    formatMoney,
    getDateTimeFormate,
} from "../../sharedfiles/helper";
import No__Data__Found from "../Common/table/No__Data__Found";
import { useDispatch, useSelector } from "react-redux";
import { COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import { getAutoWatchList, handleActivation } from "./watchLIstSlice";

const CreateWatchlistTable = () => {
    const { getAutoWatchlistData } = useSelector(
        (state) => state.CreateWatchListSlice
    );
    const dispatch = useDispatch();

    const [activeProcessing, setActiveProcessing] = useState(null);
    const [deactiveProcessing, setDeactiveProcessing] = useState(null);

    const tbHeader = [
        "Deposit to Withdraw Ratio",
        `Total Liability to stake ${Currency()}`,
        "Cashout Per Day",
        "Total Cashout Bets (%)",
        "No of withdrawals per day",
        "Bet Type",
        "Average Odds (<)",
        `Stake (>) ${Currency()}`,
        "Created By",
        "Created Date",
        "Updated By",
        "Updated Date",
        // "Action",
    ];

    const info = [
        "Showing Deposit to Withdraw Ratio",
        "Showing Total Liability to stake",
        "Showing Cashout Per Day",
        "Showing Total Cashout Bets (%)",
        "Showing No of withdrawals per day",
        "Showing Bet Type",
        "Showing Average Odds (<)",
        "Showing Stake (>)",
        "Showing Created By",
        "Showing Created Date",
        "Showing Updated By",
        "Showing Updated Date",
        // "Showing Action",
    ];

    // const handleAction = useCallback((id, status) => {
    //     if (status === "ACTIVE" && activeProcessing !== id) {
    //         setActiveProcessing(id);
    //     } else if (status === "IN_ACTIVE" && deactiveProcessing !== id) {
    //         setDeactiveProcessing(id);
    //     }

    //     const payload = {
    //         automatedWatchListId: id,
    //         status
    //     };

    //     dispatch(handleActivation(payload))
    //         .finally(() => {
    //             setTimeout(() => {
    //                 dispatch(getAutoWatchList(0, 25));
    //                 setActiveProcessing(null);
    //                 setDeactiveProcessing(null);
    //             }, 2500);
    //         });
    // }, [dispatch, activeProcessing, deactiveProcessing]);


    return (
        <div>
            <div className="CMS-box">
                <div className="CMS-table CMS-table-triped">
                    <table>
                        <thead>
                            <tr>
                                {tbHeader.map((val, ind) => (
                                    <th key={ind}>
                                        <div
                                            style={{ whiteSpace: "nowrap" }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <p className="mx-3">{val}</p>
                                            <Tooltips info={info[ind]} />
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {getAutoWatchlistData?.data &&
                                Array.isArray(getAutoWatchlistData.data) &&
                                getAutoWatchlistData.data.length > 0 ? (
                                getAutoWatchlistData.data.map((val, ind) => (
                                    <tr key={ind}>
                                        <td>{val?.depositToWithdrawRatio}</td>
                                        <td>{formatMoney(val?.totalLiabilityToStake)}</td>
                                        <td>{val?.cashoutPerDay}</td>
                                        <td>{val?.totalCashoutBetsPercentage}</td>
                                        <td>{val?.withdrawalsPerDay}</td>
                                        <td>{val?.betTypeId}</td>
                                        <td>{val?.averageOdds}</td>
                                        <td>{formatMoney(val?.stake)}</td>
                                        <td>{val?.createdBy || '-'}</td>
                                        <td>{getDateTimeFormate(val?.createdDate)}</td>
                                        <td>{val?.updatedBy || '-'}</td>
                                        <td>{getDateTimeFormate(val?.updatedDate)}</td>
                                        {/* <td>
                                            <button
                                                onClick={() => handleAction(val.id, "ACTIVE")}
                                                className={`CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusActive pointer ${(activeProcessing === val.id || val?.status === "ACTIVE") ? "disable-button" : ""}`}
                                                disabled={activeProcessing === val.id}
                                            >
                                                {COMMON_CONSTANTS.active}
                                            </button>
                                            <button
                                                onClick={() => handleAction(val.id, "IN_ACTIVE")}
                                                className={`CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusClosed pointer ${(deactiveProcessing === val.id || val?.status !== "ACTIVE") ? "disable-button" : ""}`}
                                                style={{ marginTop: "5px" }}
                                                disabled={deactiveProcessing === val.id}
                                            >
                                                {COMMON_CONSTANTS.deactive}
                                            </button>
                                        </td> */}
                                    </tr>
                                ))
                            ) : (
                                <No__Data__Found colspan={50} />
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CreateWatchlistTable;
