import React, { useEffect, useState } from 'react'
import { createWtchListData1, createWtchListData2 } from '../Common/data/mapData';
import Input from '../Common/common_inputs/Input';
import { Betting } from '../../sharedfiles/tooltipMsgs';
import { BetType, SportsBetType } from '../Common/data/BettingHistory/BettinghistoryData';
import Select from '../Common/common_inputs/Select_DDL';
import ButtonGroup from '../Common/Buttons/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { addAutoWatchList, getAutoWatchList, getBetTypes, setIsVisible, setPaginationFirstValue, setPaginationSecondValue, setRecordsShow } from './watchLIstSlice';
import { Currency, MoneyFormat2, cleanObject, formatMoney, getDateTimeFormate, regexType } from '../../sharedfiles/helper';
import Table from '../Common/table/Table';
import { exportPDFC } from '../../sharedfiles/jsPdfCommon';
import Vpagination from '../../sharedfiles/Vpagination';
import Skelton from '../Common/skelton/Skelton';
import CreateWatchlistTable from './CreateWatchlistTable';

const CreateWatchList = () => {
    const dispatch = useDispatch()
    const { getAutoWatchlistData, paginationFirstValue, paginationSecondValue, recordsShow, isVisible, betType } = useSelector(p => p.CreateWatchListSlice)
    const [formData, setFormData] = useState({
        depoWithdrawRatio: '',
        totalLiability: '',
        cashoutPerDay: '',
        totalCashoutBets: '',
        noWithdrawDay: '',
        bettingType: '',
        averageOdds: '',
        stake: ''
    })
    const [tableData, setTableData] = useState('')
    const [csvData, setCsvData] = useState([])
    const [pdfBody, setPdfBody] = useState([])
    const [disableBtn, setDisableBtn] = useState(false)
    const [error, setError] = useState([]);
    const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: name === 'bettingType' ? Number(value) : value,
        }
        ));

        if (name === "bettingType" && value !== '') {
            let errors = [];

            if (value === '') {
                errors.push("averageOdds");
            } else if (formData.averageOdds === '') {
                errors.push("averageOdds");
            }

            if (value === '') {
                errors.push("stake");
            } else if (formData.stake === '') {
                errors.push("stake");
            }

            setError(errors);
        } else {
            if (name === "averageOdds") {
                setError(prevErrors => {
                    if (value === "") {
                        return [...prevErrors, "averageOdds"];
                    } else {
                        return prevErrors.filter(error => error !== "averageOdds");
                    }
                });
            }

            if (name === "stake") {
                setError(prevErrors => {
                    if (value === "") {
                        return [...prevErrors, "stake"];
                    } else {
                        return prevErrors.filter(error => error !== "stake");
                    }
                });
            }
        }
    }


    const onCreate = () => {
        let errors = [];

        if (formData.bettingType !== '') {
            if (formData.averageOdds === "") {
                errors.push("averageOdds");
            }
            if (formData.stake === "") {
                errors.push("stake");
            }
        }

        setError(errors);

        const payload = {
            "averageOdds": formData.averageOdds,
            "betTypeId": formData.bettingType,
            "cashOutPerDay": formData.cashoutPerDay,
            "depositToWithdrawRatio": formData.depoWithdrawRatio,
            "stake": MoneyFormat2(formData.stake),
            "totalCashOutBetsPercentage": formData.totalCashoutBets,
            "totalLiabilityToStake": MoneyFormat2(formData.totalLiability),
            "withdrawalsPerDay": formData.noWithdrawDay
        };

        const finalPayload = cleanObject(payload);

        if (Object.keys(finalPayload).length > 0) {
            if (errors.length === 0) {
                setDisableBtn(true);
                dispatch(addAutoWatchList(finalPayload));
                setTimeout(() => {
                    dispatch(getAutoWatchList(0, 25));
                    // callApi(1, 25)
                    onReset();
                }, 500);
                setTimeout(() => {
                    setDisableBtn(false);
                }, 3000);
            }
        }
    }



    const onReset = () => {
        setFormData({
            depoWithdrawRatio: '',
            totalLiability: '',
            cashoutPerDay: '',
            totalCashoutBets: '',
            noWithdrawDay: '',
            bettingType: '',
            averageOdds: '',
            stake: ''
        })
        setError([]);
    }

    const tbHeader = [
        [
            "Deposit to Withdraw Ratio",
            `Total Liability to stake ${Currency()}`,
            "Cashout Per Day",
            "Total Cashout Bets (%)",
            "No of withdrawals per day",
            "Bet Type",
            "Average Odds (<)",
            `Stake (>) ${Currency()}`,
            "Created By",
            "Created Date",
            "Updated By",
            "Updated Date"
        ]
    ]
    const info = [
        "Showing Deposit to Withdraw Ratio",
        "Showing Total Liability to stake",
        "Showing Cashout Per Day",
        "Showing Total Cashout Bets (%)",
        "Showing No of withdrawals per day",
        "Showing Bet Type",
        "Showing Average Odds (<)",
        "Showing Stake (>)",
        "Showing Created By",
        "Showing Created Date",
        "Showing Updated By",
        "Showing Updated Date"
    ]


    const update = () => {
        const tbody = getAutoWatchlistData &&
            getAutoWatchlistData.data ? getAutoWatchlistData.data.map((p) => [
                p.depositToWithdrawRatio,
                formatMoney(p.totalLiabilityToStake),
                p.cashoutPerDay,
                p.totalCashoutBetsPercentage,
                p.withdrawalsPerDay,
                p.betTypeId,
                p.averageOdds,
                formatMoney(p.stake),
                p.createdBy || '-',
                getDateTimeFormate(p.createdDate),
                p.updatedBy || '-',
                getDateTimeFormate(p.updatedDate)
            ]) : []
        setPdfBody(tbody)
        const data = tbody && tbody?.length > 0 ? [tbHeader[0], ...tbody] : [tbHeader[0], []];
        setCsvData(data)
        setTableData([tbHeader[0], info, ...tbody])

    }

    const exportPDF = () => {
        const title = "Create Watchlist Report";
        exportPDFC(title, tbHeader, pdfBody)
    }

    const callApi = (pageNumber, itemsPerPage) => {
        dispatch(getAutoWatchList(
            pageNumber - 1,
            itemsPerPage
        ))
    }

    useEffect(() => {
        dispatch(getBetTypes())
        callApi(1, 25)
    }, [])

    useEffect(() => {
        update()
    }, [getAutoWatchlistData])
    const betTypes = betType.map((bet) => ({
        value: bet.betTypeId,
        label: bet.betTypeName
    }));

    const isFormIncomplete = !Object.values(formData).some(value => value !== '')

    return (
        <React.Fragment>
            <div className='CMS-tab-panel active'>
                <div className='CMS-tabContent'>
                    <div className='CMS-box CMS-box-content'>
                        <div className='row '>
                            {createWtchListData1.map((item, ind) => {
                                return (
                                    <React.Fragment key={item.id} >
                                        <div
                                            className='col-3'
                                        >
                                            <Input
                                                title={item.title}
                                                name={item.name}
                                                value={formData[item.name]}
                                                placeholder={item.placeholder}
                                                setValue={(e) => handleChange(e)}
                                                regex={item.regex}
                                                maxLen={item.maxLength}
                                                ids={item.ids}
                                                info={item.info}
                                                show={true}
                                            />
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className='row '>
                            <div className='col-3'>
                                <Input
                                    title={"No of withdrawals per day"}
                                    name={"noWithdrawDay"}
                                    value={formData.noWithdrawDay}
                                    placeholder={"No of withdrawals per day"}
                                    setValue={(e) => handleChange(e)}
                                    ids={"noWiddwalwatchlist"}
                                    info={"Enter No of withdrawals per day"}
                                    show={true}
                                    regex={regexType.oneDecimal}
                                />
                            </div>
                            <div className='col-3'>
                                <Select
                                    title={"Bet Type"}
                                    name={"bettingType"}
                                    ids={"BetType"}
                                    value={formData.bettingType}
                                    setValue={(val) => handleChange(val)}
                                    options={betTypes}
                                    info={Betting.Bet_Type}
                                />
                            </div>
                            {createWtchListData2.map((item, ind) => {
                                return (
                                    <React.Fragment key={item.id} >
                                        <div
                                            className='col-3'
                                        >
                                            <Input
                                                title={item.title}
                                                name={item.name}
                                                value={formData[item.name]}
                                                placeholder={item.placeholder}
                                                setValue={(e) => handleChange(e)}
                                                regex={item.regex}
                                                maxLen={item.maxLength}
                                                ids={item.ids}
                                                info={item.info}
                                                show={true}
                                                errorMsg={error.length > 0 &&
                                                    error.includes(item.name) && item.errorMsg}
                                            />
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className='row'>
                            <ButtonGroup
                                groupNames={["Create", "Reset"]}
                                idsearch={"createWatchlist"}
                                idreset={"resetWatchlist"}
                                submit={(e) => onCreate(e)}
                                reset={(e) => onReset(e)}
                                freezButton={isFormIncomplete || viewOnlyuser}
                            />
                        </div>
                    </div>

                    <div className='CMS-filter-result' id='result'>
                        <div className="CMS-pagination">
                            <Vpagination
                                data={getAutoWatchlistData.data}
                                totalRecords={getAutoWatchlistData.totalRecords}
                                paginationFirstValue={paginationFirstValue}
                                paginationSecondValue={paginationSecondValue}
                                setPaginationFirstValue={setPaginationFirstValue}
                                setPaginationSecondValue={setPaginationSecondValue}
                                recordsShow={recordsShow}
                                setRecordsShow={setRecordsShow}
                                isVisible={isVisible}
                                setIsVisible={setIsVisible}
                                csvData={csvData}
                                exportPDF={exportPDF}
                                callApi={callApi}
                                csvFileName={"Create Watchlist Report"}
                            // reloadRequired={false}
                            />
                        </div>
                    </div>

                    {!isVisible ? <Skelton vLen={5} hLen={7} /> :
                        <CreateWatchlistTable />
                        // <Table
                        //     tableData={tableData}
                        // />
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreateWatchList
