import { biDashboardLink } from "../../sharedfiles/EndpointConfig";

export const dashboardReportProd = [
    {
        ReportCatagory: 'Dashboard Reports',
        Reports: [
            {
                id: 1,
                name: "Overview",
                url: biDashboardLink.overViewReport
            },
            {
                id: 2,
                name: "Sports Overview Counts",
                url: biDashboardLink.sportsOverViewReport
            },
            {
                id: 3,
                name: "Sports Overview Amounts",
                url: biDashboardLink.sportsOverViewAmountsReport
            },
            {
                id: 4,
                name: "Casino Overview",
                url: biDashboardLink.casinoOverViewReport
            },
            {
                id: 5,
                name: "NE Games Overview",
                url: biDashboardLink.gamesOverViewReport
            },
            {
                id: 6,
                name: "Finance Overview",
                url: biDashboardLink.financeOverViewReport
            }
        ]
    }
]