import React, { useState, useRef } from "react";
import Select from "../Common/common_inputs/Select_DDL";
import { boosted_odds } from "../../sharedfiles/tooltipMsgs";
import Input from "../Common/common_inputs/Input";
import Tooltips from "../Common/Tooltip";
import {
  MomentUTCDateTime,
  MoneyFormat2,
  extractedDate,
  extractedTime,
  formatMoney,
  getDateTimeFormate,
  regexType,
  roundDecimalnum,
} from "../../sharedfiles/helper";
import { COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Table from "../Common/table/Table";
import { useEffect } from "react";
import {
  boostedOddsDates,
  boostedOddsErrs,
  boostedOddsInputs1,
  boostedOddsInputs2,
} from "../Common/data/mapData";
import Datepicker from "../Common/common_inputs/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  boostedOddsSearchApi,
  createBoostedOdds,
  getAllBoostedOdds,
  getAllCountriesBySport,
  getAllMarketsByEvent,
  getAllSportsByBrand,
  getEventsBySportCountryLeague,
  getLeaguesBysportAndCountry,
  getOddsByOutcomeId,
  getOutcomesByMarketOrSubmarket,
  getSubMarketByMarketEvent,
  setIsVisible,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordToShow,
  updateBoostedOddsStatus,
  viewBoostedOddsUsers,
} from "./boostedOddsSlice";
import No__Data__Found from "../Common/table/No__Data__Found";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import { getAllUserTags } from "../CreatePromoMsg/CreatePromoSlice";

const BoostedOdds = () => {
  const dispatch = useDispatch();
  const copyRefs = useRef([]);
  const [tags, setTags] = useState("");
  const [timeLimit, setTimeLimit] = useState("");
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [tableData, setTableData] = useState("");
  const [odds, setOdds] = useState("");
  const [sports, setSports] = useState();
  const [sportId, setSportId] = useState("");
  const [countries, setCountries] = useState("");
  const [countryId, setCountryId] = useState("");
  const [leagues, setLeagues] = useState("");
  const [events, setEvent] = useState("");
  const [eventId, setEventId] = useState("");
  const [market, setMarket] = useState("");
  const [marketId, setMarketId] = useState("");
  const [subMarket, setSubMarket] = useState("");
  const [subMarketId, setSubMarketId] = useState("");
  const [outcomes, setOutcomes] = useState("");
  const [search, setSearch] = useState("");
  const [boosted, setBoosted] = useState("");
  const [boostedOdds, setBoostedOdds] = useState("");
  const [minLimit, setMinLimit] = useState("");
  const [maxLimit, setMaxLimit] = useState("");
  const [liabilityLimit, setLiabilityLimit] = useState("");
  const [maxStake, setMaxStake] = useState("");
  const [addTbData, setAddTbData] = useState([]);
  const [manualTbheader, setManualTbheader] = useState([]);
  const currentDateTime = new Date();
  const [dateFields, setDateField] = useState({
    startDate: currentDateTime,
    endDate: "",
  });
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [maxAPiData, setMaxAPiData] = useState(new Date());
  const [error, setError] = useState([]);
  const [tagErr, setTagErr] = useState(false);
  const [timeLimiErr, setTimeLimitErr] = useState(false);
  const [sportErr, setSportErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [LeagueErr, setLeagueErr] = useState(false);
  const [eventErr, setEventErr] = useState(false);
  const [marketErr, setMarketErr] = useState(false);
  const [submarketErr, setSubMarketErr] = useState(false);
  const [outcomeErr, setOutcomeErr] = useState(false);
  const [tableShow, setTableShow] = useState(false);
  const [pdfBody, setPdfBody] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [timeDisable, setTimeDisable] = useState(true);
  const [configOpen, setConfigOpen] = useState(false);
  const [rangeErr, setRangeErr] = useState({
    maxLimitErr: "",
    minLimitErr: "",
  });
  const [timeErr, setTimeErr] = useState("");
  const [minTime, setMinTime] = useState(new Date());
  const [maxTime, setMaxTime] = useState(new Date(new Date().setHours(23, 59)));
  const allBoostedOdds = useSelector((p) => p.BoostedOddsSlice.allBoostedOdds);
  const sportByBrand = useSelector((p) => p.BoostedOddsSlice.sportByBrand);
  const countryBySport = useSelector((p) => p.BoostedOddsSlice.countryBySport);
  const leagueBySportCountry = useSelector(
    (p) => p.BoostedOddsSlice.leagueBySportCountry
  );
  const eventsbyLeagues = useSelector(
    (p) => p.BoostedOddsSlice.eventBySportCountryLeague
  );
  const marketbyEvent = useSelector((p) => p.BoostedOddsSlice.marketByEvent);
  const subMarketByMarket = useSelector((p) => p.BoostedOddsSlice.subMarket);
  const outcomesOpt = useSelector((p) => p.BoostedOddsSlice.outComes);
  const oddsByOutcome = useSelector((p) => p.BoostedOddsSlice.oddsByOutcome);
  const firstPageVal = useSelector(
    (p) => p.BoostedOddsSlice.paginationFirstValue
  );
  const secondPageVal = useSelector(
    (p) => p.BoostedOddsSlice.paginationSecondValue
  );
  const isVisible = useSelector((p) => p.BoostedOddsSlice.isVisible);
  const recordToShow = useSelector((p) => p.BoostedOddsSlice.recordToShow);
  const totalRecord = useSelector((p) => p.BoostedOddsSlice.totalRecord);
  const boostedOddsUser = useSelector(
    (p) => p.BoostedOddsSlice.viewBoostedOddUser
  );
  const alluserTags = useSelector((p) => p.CreatePromoSlice.userTags);
  const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)
  const [isOddsThere, setisOddsThere] = useState(false);

  const newSportsArray = sportByBrand.map((sport) => ({
    id: sport.sportId,
    label: sport.sportName,
    value: sport.sportId,
  }));

  const newCountryArray = countryBySport.countries
    ? countryBySport.countries.map((country) => ({
      id: country.countryId,
      label: country.countryName,
      value: country.countryId,
    }))
    : [];

  const LeagueArray = leagueBySportCountry.leagues
    ? leagueBySportCountry.leagues.map((league) => ({
      id: league.leagueId,
      label: league.leagueName,
      value: league.leagueId,
    }))
    : [];
  const EventArray = eventsbyLeagues.events
    ? eventsbyLeagues.events.map((event) => ({
      id: event.eventId,
      label: event.eventName,
      value: event.eventId,
      eventTime: event.eventStartDate,
    }))
    : [];

  const MarketArray = marketbyEvent.markets
    ? marketbyEvent.markets.map((event) => ({
      id: event.marketId,
      label: event.marketName,
      value: event.marketId,
    }))
    : [];

  const subMarketArray = subMarketByMarket.subMarkets
    ? subMarketByMarket.subMarkets.map((subm) => ({
      id: subm.subMarketName,
      label: subm.subMarketName,
      value: subm.subMarketName,
    }))
    : [];

  const outcomesArray = outcomesOpt.outComes
    ? outcomesOpt.outComes.map((p) => ({
      id: p.outComeId,
      label: p.outComeName,
      value: p.outComeId,
    }))
    : [];

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    if (name === "tags") {
      setTags(value);
      setTagErr(false);
    }
    if (name === "action") {
      const newActions = [...action]; // Create a copy of the actions array
      newActions[id] = value; // Update the action for the specific item
      setAction(newActions); // Update the state with the new actions array

      const payload = {
        id: id,
        status: value,
      };
      dispatch(updateBoostedOddsStatus(payload));
      // newActions[id] = ""; // Reset the value
      // setAction(newActions);
    }
  };

  const onSelection = (e) => {
    const { name, value } = e.target;
    if (name === "sports") {
      setSports(value);
      setSportErr(false);
      dispatch(getAllCountriesBySport(value));
      setSportId(value);
    }
    if (name === "countries") {
      setCountries(value);
      setCountryErr(false);
      dispatch(getLeaguesBysportAndCountry(sportId, value));
      setCountryId(value);
    }
    if (name === "leagues") {
      setLeagues(value);
      setLeagueErr(false);
      dispatch(getEventsBySportCountryLeague(sportId, countryId, value));
    }
    if (name === "events") {
      setEvent(value);
      setEventErr(false);
      dispatch(getAllMarketsByEvent(value));
      setEventId(value);

      const selectedEvent = EventArray.find((event) => event.value === value);
      if (selectedEvent) {
        const convertedTime = new Date(selectedEvent.eventTime);
        setDateField((prevState) => ({
          ...prevState,
          endDate: convertedTime,
        }));
        setMaxDate(convertedTime);
        setMaxAPiData(convertedTime);
        setMaxTime(convertedTime);
        setMinDate(new Date());
        setTimeDisable(false);
        // const minTime = new Date();
        // minTime.setHours(0, 0, 0, 0);
        // setMinTime(minTime);
      } else {
        setDateField({ ...dateFields, endDate: "" });
      }
    }
    if (name === "market") {
      setMarket(value);
      setMarketErr(false);
      dispatch(getSubMarketByMarketEvent(eventId, value));
      dispatch(getOutcomesByMarketOrSubmarket(eventId, value));
      setMarketId(value);
    }
    if (name === "subMarket") {
      setSubMarket(value);
      setSubMarketErr(false);
      dispatch(getOutcomesByMarketOrSubmarket(eventId, marketId, value));
      setSubMarketId(value);
    }
    if (name === "outcomes") {
      setOutcomes(value);
      setOutcomeErr(false);
      const subMarketVal = subMarket === 0 ? null : subMarket;
      dispatch(getOddsByOutcomeId(eventId, marketId, value, subMarketVal));
    }
  };

  const validation = () => {
    let isValid = true;

    const stratDateTime = extractedTime(dateFields.startDate);
    const endDateTime = extractedTime(dateFields.endDate);

    const stratDateObj = new Date(dateFields.startDate);
    const endDateObj = new Date(dateFields.endDate);

    if (stratDateObj.getDate() === endDateObj.getDate()) {
      if (stratDateTime > endDateTime) {
        setTimeErr("Start Date Time should not be higher than End Date Time");
        isValid = false;
      }
    }
    const minErr = "Minimum Limit should not be more than Boosted Odds Value";
    const maxErr = "Maximum  Limit should not be more than Boosted Odds Value";
    if (sports === "") {
      setSportErr(true);
      isValid = false;
    }
    if (tags === "") {
      setTagErr(true);
      isValid = false;
    }
    if (
      boosted === "" &&
      minLimit === "" &&
      maxLimit === "" &&
      liabilityLimit === "" &&
      maxStake === ""
    ) {
      setError(boostedOddsErrs);
      isValid = false;
    }
    // if (maxLimit > boostedOdds) {
    //     setRangeErr({ maxLimitErr: maxErr })
    //     isValid = false;

    // } else if (minLimit > boostedOdds) {
    //     setRangeErr({ minLimitErr: minErr })
    //     isValid = false;

    // } else {
    //     setRangeErr('')
    // }
    if (countries === "") {
      setCountryErr(true);
      isValid = false;
    }
    if (leagues === "") {
      setLeagueErr(true);
      isValid = false;
    }
    if (events === "") {
      setEventErr(true);
      isValid = false;
    }
    if (market === "") {
      setMarketErr(true);
      isValid = false;
    }
    // if (subMarket === "") {
    //     setSubMarketErr(true);
    //     isValid = false;
    // }
    if (outcomes === "") {
      setOutcomeErr(true);
      isValid = false;
    }
    return isValid;
  };

  const onSearchHandle = (e) => {
    dispatch(boostedOddsSearchApi(search, 0, 25));
  };

  const dateHandle = (value, name) => {
    if (name == "endDate") {
      setMaxDate(value);
    }
    if (name == "startDate") {
      setMinDate(value);
      setTimeErr("");
    }
    setDateField((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setTimeDisable(false);
    if (value instanceof Date && !isNaN(value)) {
      const today = new Date();
      const isToday = value.toDateString() === today.toDateString();
      const selectedEvent = EventArray.find((event) => event.value === events);
      const eventDateTime = new Date(selectedEvent?.eventTime);
      const eventTimestamp = eventDateTime.getTime();
      const valueTimestamp = value.getTime();
      const startDateVal = value.toDateString();
      const startDateApiVal = eventDateTime.toDateString();

      if (name === "endDate") {
        if (valueTimestamp === eventTimestamp) {
          setMaxTime(eventDateTime);
        } else if (startDateVal === dateFields.startDate.toDateString()) {
          setMaxTime(new Date(value));
        } else if (value < eventDateTime && value > isToday) {
          const maxTime = new Date();
          maxTime.setHours(23, 59, 59, 999);
          setMaxTime(maxTime);
          const minTime = new Date();
          minTime.setHours(0, 0, 0, 0);
          setMinTime(minTime);
        } else {
          setMaxTime(new Date(0, 0, 0, 23, 59));
        }
      }

      if (name === "startDate") {
        if (startDateVal === startDateApiVal) {
          setMaxTime(dateFields.endDate);
          const minTime = new Date();
          minTime.setHours(0, 0, 0, 0);
          setMinTime(minTime);
        } else if (value < eventDateTime && value > today) {
          const maxTime = new Date();
          maxTime.setHours(23, 59, 59, 999);
          setMaxTime(maxTime);
          const minTime = new Date();
          minTime.setHours(0, 0, 0, 0);
          setMinTime(minTime);
        } else {
          setMaxTime(new Date(0, 0, 0, 23, 59));
        }
      }

      if (isToday) {
        setMinTime(new Date());
        setMaxTime(new Date(new Date().setHours(23, 59)));
      }
    } else {
      setMinTime(new Date());
      setMaxTime(new Date(new Date().setHours(23, 59)));
    }
  };

  useEffect(() => {
    setOdds(oddsByOutcome.odds);
    if (oddsByOutcome.odds) {
      setisOddsThere(true)
    }
  }, [oddsByOutcome]);

  const inputHandle = (e) => {
    const { name, value } = e.target;
    const minErr = "Minimum Limit should not be more than Boosted Odds Value";
    const maxErr = "Maximum  Limit should not be more than Boosted Odds Value";

    if (name === "timeLimit") {
      setTimeLimit(value);
      setTimeLimitErr(false);
    }
    if (name === "boosted") {
      setBoosted(value);
      if (isOddsThere) {
        const boostedOddsCalc =
          (parseFloat(odds) * parseFloat(value)) / 100 + parseFloat(odds);
        const roundedVal = roundDecimalnum(boostedOddsCalc);
        setBoostedOdds(roundedVal);
      }
      setError(error.filter((item) => item !== name));
    }
    if (name === "minLimit") {
      setError(error.filter((item) => item !== name));
      // if (boostedOdds !== '' && value > boostedOdds) {
      //     setRangeErr({ minLimitErr: minErr })
      // } else {
      //     setRangeErr('')
      // }
      setMinLimit(value);
    }
    if (name === "maxLimit") {
      setError(error.filter((item) => item !== name));
      // if (boostedOdds !== '' && value > boostedOdds) {
      //     setRangeErr({ maxLimitErr: maxErr })
      // } else {
      //     setRangeErr('')
      // }
      setMaxLimit(value);
    }
    if (name === "liabilityLimit") {
      setLiabilityLimit(value);
      setError(error.filter((item) => item !== name));
    }
    if (name === "maxStake") {
      setMaxStake(value);
      setError(error.filter((item) => item !== name));
    }
  };

  const onViewBtn = (id) => {
    setOpen(true);
    dispatch(viewBoostedOddsUsers(id));
    update();
  };

  //PAYLOAD DATA && ADD TABLE MAP DATA//
  const sportArray = newSportsArray?.map((obj) => {
    if (obj?.id == sports) {
      return obj?.label;
    }
  });
  const filteredsportName = sportArray.filter((name) => name !== undefined);
  const sportName = filteredsportName[0];

  const countryArray = newCountryArray?.map((obj) => {
    if (obj?.id == countries) {
      return obj?.label;
    }
  });
  const filteredCountryArray = countryArray.filter(
    (name) => name !== undefined
  );
  const countryName = filteredCountryArray[0];

  const leagueArray = LeagueArray?.map((obj) => {
    if (obj?.id == leagues) {
      return obj?.label;
    }
  });
  const filteredleagueArray = leagueArray.filter((name) => name !== undefined);
  const leagueName = filteredleagueArray[0];

  const marketNameArray = MarketArray?.map((obj) => {
    if (obj?.id == market) {
      return obj?.label;
    }
  });
  const filteredmarketName = marketNameArray.filter(
    (name) => name !== undefined
  );
  const marketName = filteredmarketName[0];

  const outComeNameArray = outcomesArray?.map((obj) => {
    if (obj?.id == outcomes) {
      return obj?.label;
    }
  });
  const filteredoutComeName = outComeNameArray.filter(
    (name) => name !== undefined
  );
  const outComeName = filteredoutComeName[0];

  const eventNameArray = EventArray?.map((obj) => {
    if (obj?.id == events) {
      return obj?.label;
    }
  });
  const filteredeventNameArray = eventNameArray.filter(
    (name) => name !== undefined
  );
  const eventName = filteredeventNameArray[0];
  const allStates = {
    sportId: sports,
    sportName: sportName,
    countryId: countries,
    countryName: countryName,
    leagueId: leagues,
    leagueName: leagueName,
    marketId: market,
    marketName: marketName,
    eventId: events,
    eventName: eventName,
    outComeId: outcomes,
    outComeName: outComeName,
    subMarketId: subMarket,
    subMarketName: subMarket,
    odds: odds,
    boostedPercentage: boosted,
    boostedOddsValue: boostedOdds,
    minimumLimit: +minLimit,
    maximumLimit: +maxLimit,
    liabilityLimit: MoneyFormat2(liabilityLimit),
    startDate: MomentUTCDateTime(dateFields.startDate),
    endDate: MomentUTCDateTime(dateFields.endDate),
    tagName: tags,
    maxStake: MoneyFormat2(maxStake),
    timeToLimit: timeLimit,
  };
  //PAYLOAD DATA && ADD TABLE MAP DATA//

  const onAdd = () => {
    if (validation()) {
      setTableShow(true);
      setManualTbheader(addTbheader);
      setAddTbData((prevData) => [...prevData, allStates]);
    }
  };

  const onDelete = (ind) => {
    setAddTbData((prevData) => prevData.filter((item, index) => index !== ind));
  };

  const onCreate = () => {
    if (validation()) {
      const payloadData = tableShow ? addTbData : [allStates];
      dispatch(createBoostedOdds({ boostedOdds: payloadData }));
      dispatch(boostedOddsSearchApi(null, 0, 25));
    }
  };

  const onCancel = () => {
    setTagErr("");
    setSportErr("");
    setTags("");
    setTimeLimit("");
    setOdds("");
    setSports("");
    setCountries("");
    setLeagues("");
    setEvent("");
    setMarket("");
    setSubMarket("");
    setOutcomes("");
    setBoosted("");
    setBoostedOdds("");
    setMinLimit("");
    setMaxLimit("");
    setLiabilityLimit("");
    setMaxStake("");
    setDateField({
      startDate: currentDateTime,
      endDate: "",
    });
    setError([]);
    setOutcomeErr("");
    setEventErr("");
    setMarketErr("");
    setCountryErr("");
    setLeagueErr("");
    setTimeLimitErr("");
    setAddTbData([]);
    setManualTbheader([]);
    setTimeDisable(true);
    setTimeErr("");
  };

  const onConfig = () => {
    setConfigOpen(true);
  };
  const copyText = (item, curentBtn) => {
    navigator.clipboard.writeText(item);
    setTags(item.tagName);
    setTimeLimit(item.timeToLimit);
    // setSports(item.sportId)
    // setCountries(item.countryId)
    // setLeagues(item.leagueId)
    // setMarket(item.marketId)
    // setOutcomes(item.outComeId)
    setBoosted(item.boostedPercentage);
    setOdds(item.odds);
    setBoostedOdds(item.boostedOddsValue);
    setMinLimit(formatMoney(item.minimumLimit));
    setMaxLimit(formatMoney(item.maximumLimit));
    setLiabilityLimit(formatMoney(item.liabilityLimit));
    setMaxStake(formatMoney(item.maxStake));
    setDateField({
      startDate: new Date(item.startDate),
      endDate: new Date(item.endDate),
    });

    if (curentBtn !== null && curentBtn !== undefined) {
      curentBtn.style.display = "block";
      setTimeout(() => (curentBtn.style.display = "none"), 1000);
    }
  };

  const theader = [
    "Customer ID",
    "Bet ID",
    "Short Bet ID",
    "Stake",
    "Odds",
    "Liability Limit",
    "Remaining Liability",
    "Max Stake",
  ];

  const info = [
    "Showing Customer ID",
    "Showing Bet ID",
    "Showing Short Bet ID",
    "Showing Stake",
    "Showing Odds",
    "Showing Liability Limit",
    "Showing Remaining Liability",
    "Showing Max Stake",
  ];

  const searchTbHeader = [
    [
      "Sport",
      "Country",
      "League",
      "Market",
      "Outcome",
      "SR Odds",
      "% Boosted",
      "Boosted Odds",
      "Min Limit",
      "Max Limit",
      "Liability Limit",
      "Remaining Liability",
      "Start Date Time",
      "End Date Time",
      "Tags",
      "Max Stake",
      "Time to Limit",
    ],
  ];
  const update = () => {
    const tbody =
      boostedOddsUser && boostedOddsUser.length > 0
        ? boostedOddsUser.map((p) => [
          p.userId,
          p.betId,
          p.shortBetId,
          formatMoney(p.stake),
          p.odds,
          formatMoney(p.liabilityLimit),
          formatMoney(p.remainingLiability),
          formatMoney(p.maxStake),
        ])
        : [];
    setTableData([theader, info, ...tbody]);
  }; //popup table

  useEffect(() => {
    const searchTbData =
      allBoostedOdds && allBoostedOdds.length > 0
        ? allBoostedOdds?.map((item, ind) => [
          item.sportName,
          item.countryName,
          item.leagueName,
          item.marketName,
          item.outComeName,
          item.odds,
          item.boostedPercentage,
          item.boostedOddsValue,
          formatMoney(item.minimumLimit),
          formatMoney(item.maximumLimit),
          formatMoney(item.liabilityLimit),
          item.remainingLiability,
          getDateTimeFormate(item.startDate),
          getDateTimeFormate(item.endDate),
          item.tagName,
          formatMoney(item.maxStake),
          item.timeToLimit,
        ])
        : [];
    setPdfBody(searchTbData);
    const data =
      Array.isArray(searchTbData) && searchTbData.length > 0
        ? [searchTbHeader[0], ...searchTbData]
        : [searchTbData[0], []];
    setCsvData(data);
  }, [allBoostedOdds]);

  useEffect(() => {
    update();
  }, [boostedOddsUser]);

  useEffect(() => {
    dispatch(setIsVisible(true));
    dispatch(boostedOddsSearchApi(null, 0, 25));
    dispatch(getAllUserTags());
    dispatch(getAllSportsByBrand());
  }, []);
  const tbHeaders = [
    "Sport",
    "Country",
    "League",
    "Market",
    "Outcome",
    "SR Odds",
    "% Boosted",
    "Boosted Odds",
    "Min Limit",
    "Max Limit",
    "Liability Limit",
    "Remaining Liability",
    "Start Date Time",
    "End Date Time",
    "Tags",
    "Max Stake",
    "Time to Limit",
    "Awarded",
    "Actions",
  ];
  const headerInfo = [
    "Showing Sport",
    "Showing Country",
    "Showing League",
    "Showing Market",
    "Showing Outcome",
    "Showing SR Odds",
    "Showing % Boosted",
    "Showing Boosted Odds",
    "Showing Min Limit",
    "Showing Max Limit",
    "Showing Liability Limit",
    "Showing Remaining Liability",
    "Showing Start Date Time",
    "Showing End Date Time",
    "Showing Tags",
    "Showing Max Stake",
    "Showing Time to Limit",
    "Showing Awarded",
    "Showing Actions to be taken",
  ];

  const manualHeaderInfo = [
    "Showing Sport",
    "Showing Country",
    "Showing League",
    "Showing Market",
    "Showing Outcome",
    "Showing SR Odds",
    "Showing % Boosted",
    "Showing Boosted Odds",
    "Showing Min Limit",
    "Showing Max Limit",
    "Showing Liability Limit",
    "Showing Start Date Time",
    "Showing End Date Time",
    "Showing Tags",
    "Showing Max Stake",
    "Showing Time to Limit",
    "Showing boosted odds to be Removed",
  ];
  const configHeaderInfo = [
    "Showing Sport",
    "Showing Country",
    "Showing League",
    "Showing Market",
    "Showing Outcome",
    "Showing SR Odds",
    "Showing % Boosted",
    "Showing Boosted Odds",
    "Showing Min Limit",
    "Showing Max Limit",
    "Showing Liability Limit",
    "Showing Remaining Liability",
    "Showing Start Date Time",
    "Showing End Date Time",
    "Showing Tags",
    "Showing Max Stake",
    "Showing Time to Limit",
    "Copy Config",
  ];
  const addConfigheader = [
    "Sport",
    "Country",
    "League",
    "Market",
    "Outcome",
    "SR Odds",
    "% Boosted",
    "Boosted Odds",
    "Min Limit",
    "Max Limit",
    "Liability Limit",
    "Remaining Liability",
    "Start Date Time",
    "End Date Time",
    "Tags",
    "Max Stake",
    "Time to Limit",
    "Copy Config",
  ];
  const addTbheader = [
    "Sport",
    "Country",
    "League",
    "Market",
    "Outcome",
    "SR Odds",
    "% Boosted",
    "Boosted Odds",
    "Min Limit",
    "Max Limit",
    "Liability Limit",
    "Start Date Time",
    "End Date Time",
    "Tags",
    "Max Stake",
    "Time to Limit",
    "Remove",
  ];

  const exportPDF = () => {
    const title = "Boosted Odds Records";
    const styles = {
      marginTop: "10px",
    };
    exportPDFC(title, searchTbHeader, pdfBody, styles);
  };

  const callPageApi = (pageNumber, itemsPerPage) => {
    dispatch(setIsVisible(true));
    boostedOddsSearchApi(search ? search : null, pageNumber - 1, itemsPerPage);
  };

  const sortedBoostedOdds = [...allBoostedOdds].sort((a, b) => b.id - a.id);

  return (
    <>
      <Dialog__Main
        dOpen={open}
        dClose={() => setOpen(false)}
        title={"View Users"}
        width={"700px"}
      >
        <Table tableData={tableData} linkIndex={0} />
      </Dialog__Main>
      <Dialog__Main
        dOpen={configOpen}
        dClose={() => setConfigOpen(false)}
        title={"Copy Config"}
        width={"700px"}
      >
        <div className="CMS-box CMS-box-content">
          <div className="CMS-table CMS-table-triped">
            <table>
              <thead>
                <tr>
                  {addConfigheader.map((item, index) => (
                    <th>
                      <div className="d-flex justify-content-center align-items-center">
                        <p className="mx-3">{item}</p>
                        <Tooltips info={`${configHeaderInfo[index]}`} />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedBoostedOdds && sortedBoostedOdds.length > 0 ? (
                  sortedBoostedOdds.map((item, ind) => {
                    return (
                      <tr>
                        <td>{item.sportName}</td>
                        <td>{item.countryName}</td>
                        <td>{item.leagueName}</td>
                        <td>{item.marketName}</td>
                        <td>{item.outComeName}</td>
                        <td>{item.odds}</td>
                        <td>{item.boostedPercentage}</td>
                        <td>{item.boostedOddsValue}</td>
                        <td>{formatMoney(item.minimumLimit)}</td>
                        <td>{formatMoney(item.maximumLimit)}</td>
                        <td>{formatMoney(item.liabilityLimit)}</td>
                        <td>{item.remainingLiability}</td>
                        <td>{getDateTimeFormate(item.startDate)}</td>
                        <td>{getDateTimeFormate(item.endDate)}</td>
                        <td>{item.tagName}</td>
                        <td>{formatMoney(item.maxStake)}</td>
                        <td>{item.timeToLimit}</td>
                        <td>
                          <span
                            className="material-icons"
                            data-icon={"content_copy"}
                            onClick={() =>
                              copyText(item, copyRefs.current[ind])
                            }
                          ></span>
                          <span
                            className="btn_textCopied"
                            ref={(element) => copyRefs.current.push(element)}
                          >
                            Copied
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <No__Data__Found colspan={50} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Dialog__Main>
      <div className="CMS-tab-panel active" id="CMS-boostedodds">
        <div className="CMS-tabContent">
          <div className="CMS-box CMS-box-content">
            <div className="CMS-cardBox">
              <p className="CMS-formLabel justify-content-center mb-10">
                GLOBAL CONFIGURATION
              </p>
              <div className="mt-20"></div>
              <div className="row">
                <div className="col-md-4 col-lg-3">
                  <div className="CMS-formGroup">
                    <div className="CMS-formLabel">
                      {"Tags"}
                      &nbsp;&nbsp;
                      <Tooltips info={boosted_odds.Tags} />
                    </div>
                    <div className="CMS-dropdown CMS-formControl">
                      <div className="CMS-select">
                        <select
                          name="tags"
                          id={"boostedoddtags"}
                          value={tags}
                          onChange={(val) => handleChange(val)}
                          className="pointer"
                        >
                          <option value="">
                            {COMMON_CONSTANTS.ddlOpt_select}
                          </option>
                          {Object.keys(alluserTags).length > 0 &&
                            alluserTags.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="CMS-form-errorMsg">
                        {tagErr && "Please select tag name"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <Input
                    title={"Time to limit(mins)"}
                    name={"timeLimit"}
                    value={timeLimit}
                    placeholder={"Time Limit"}
                    setValue={(e) => inputHandle(e)}
                    regex={regexType.decimalNumeric}
                    maxLen={"2"}
                    ids={"boostedoddtimelimit"}
                    info={boosted_odds.Time_limit}
                    show={true}
                    disabled={timeDisable}
                    errorMsg={timeLimiErr && "Please give Time Limit"}
                  />
                </div>
                <div
                  className="link_style ml-15 mt-35 pointer"
                  onClick={() => onConfig()}
                >
                  Copy Config
                </div>
                <div className="ml-10 mt-35">
                  <span
                    className="material-icons"
                    data-icon={"content_copy"}
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-30"></div>
          <div className="CMS-box CMS-box-content">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <Select
                  title={"Sports"}
                  name={"sports"}
                  ids={"boostedSports"}
                  value={sports}
                  setValue={(val) => onSelection(val)}
                  options={newSportsArray}
                  info={boosted_odds.Sports}
                  errorMsg={sportErr && "Please select the sport"}
                />
              </div>
              <div className="col-md-4 col-lg-3">
                <Select
                  title={"Countries"}
                  name={"countries"}
                  ids={"boostedCountries"}
                  value={countries}
                  setValue={(val) => onSelection(val)}
                  options={newCountryArray}
                  info={boosted_odds.Countries}
                  errorMsg={countryErr && "Please select the country"}
                />
              </div>
              <div className="col-md-4 col-lg-3">
                <Select
                  title={"Leagues"}
                  name={"leagues"}
                  ids={"boostedleagues"}
                  value={leagues}
                  setValue={(val) => onSelection(val)}
                  options={LeagueArray}
                  info={boosted_odds.Leagues}
                  errorMsg={LeagueErr && "Please select the league"}
                />
              </div>
              <div className="col-md-4 col-lg-3">
                <Select
                  title={"Events"}
                  name={"events"}
                  ids={"boostedevents"}
                  value={events}
                  setValue={(val) => onSelection(val)}
                  options={EventArray}
                  info={boosted_odds.Events}
                  errorMsg={eventErr && "Please select the event"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <Select
                  title={"Markets"}
                  name={"market"}
                  ids={"boostedmarket"}
                  value={market}
                  setValue={(val) => onSelection(val)}
                  options={MarketArray}
                  info={boosted_odds.Markets}
                  errorMsg={marketErr && "Please select Market"}
                />
              </div>
              <div className="col-md-4 col-lg-3">
                <Select
                  title={"Submarket"}
                  name={"subMarket"}
                  ids={"boostedsubMarkett"}
                  value={subMarket}
                  setValue={(val) => onSelection(val)}
                  options={subMarketArray}
                  info={boosted_odds.Markets}
                  errorMsg={submarketErr && "Please select Submarket"}
                />
              </div>
              <div className="col-md-4 col-lg-3">
                <Select
                  title={"Outcomes"}
                  name={"outcomes"}
                  ids={"boostedoutcomes"}
                  value={outcomes}
                  setValue={(val) => onSelection(val)}
                  options={outcomesArray}
                  info={boosted_odds.Outcomes}
                  errorMsg={outcomeErr && "Please select Outcomes"}
                />
              </div>
              <div className="col-md-4 col-lg-3 Input_Disabled">
                <Input
                  title={"Odds"}
                  name={"Odds"}
                  value={odds}
                  placeholder={"Odds"}
                  setValue={(e) => inputHandle(e)}
                  regex={regexType.decimalNumber}
                  ids={"boostedOdds"}
                  info={boosted_odds.Odds}
                  show={true}
                  className={"Input_Disabled"}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              {boostedOddsInputs1.map((item, ind) => {
                const values = [boosted, boostedOdds, minLimit, maxLimit];
                console.log("maxLimit: ", maxLimit);
                console.log("minLimit: ", minLimit);
                return (
                  <React.Fragment key={item.ids}>
                    <div className="col-md-4 col-lg-3" key={item.id}>
                      <Input
                        title={item.title}
                        name={item.name}
                        value={values[ind]}
                        placeholder={item.placeholder}
                        setValue={(e) => inputHandle(e)}
                        regex={item.regex}
                        ids={item.ids}
                        info={item.info}
                        errorMsg={
                          error.includes(item.name) && item.errorMsg
                          //error.length > 0 &&
                          // item.name === "minLimit" && rangeErr.minLimitErr || item.name === "maxLimit" && rangeErr.maxLimitErr
                        }
                        show={true}
                        disabled={item.disabled}
                        className={
                          item.name === "boostedOdds" ? "Input_Disabled" : ""
                        }
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="row">
              {boostedOddsInputs2.map((item, ind) => {
                const values = [liabilityLimit, maxStake];
                return (
                  <React.Fragment key={item.ids}>
                    <div className="col-md-4 col-lg-3" key={item.id}>
                      <Input
                        title={item.title}
                        name={item.name}
                        value={values[ind]}
                        placeholder={item.placeholder}
                        setValue={(e) => inputHandle(e)}
                        regex={item.regex}
                        ids={item.ids}
                        info={item.info}
                        errorMsg={error.includes(item.name) && item.errorMsg}
                        show={true}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
              {boostedOddsDates.map((item, index) => {
                const values = [dateFields.startDate, dateFields.endDate];
                const startDate = [new Date(), maxDate];
                const endDate = [minDate, maxAPiData];
                return (
                  <div className="col-md-4 col-lg-3">
                    <Datepicker
                      title={item.title}
                      name={item.name}
                      ids={item.ids}
                      value={values[index]}
                      setValue={(value, name) => dateHandle(value, name)}
                      showTimeSelect
                      minTime={minTime}
                      maxTime={maxTime}
                      minDate={index == 0 ? startDate[0] : endDate[0]}
                      maxDate={index == 0 ? startDate[1] : endDate[1]}
                      info={item.info}
                      errorMsg={item.name.includes("startDate") && timeErr}
                    />
                  </div>
                );
              })}
            </div>
            {tableShow ? (
              <div className="row">
                <div className="CMS-table CMS-table-triped">
                  <table>
                    <thead>
                      <tr>
                        {manualTbheader.map((item, index) => (
                          <th>
                            <div className="d-flex justify-content-center align-items-center">
                              <p className="mx-3">{item}</p>
                              <Tooltips info={`${manualHeaderInfo[index]}`} />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {addTbData.map((item, ind) => {
                        return (
                          <tr>
                            <td>{item.sportName}</td>
                            <td> {item.countryName}</td>
                            <td>{item.leagueName}</td>
                            <td>{item.marketName}</td>
                            <td>{item.outComeName}</td>
                            <td>{item.odds}</td>
                            <td>{item.boostedPercentage}</td>
                            <td>{item.boostedOddsValue}</td>
                            <td>{formatMoney(item.minimumLimit)}</td>
                            <td>{formatMoney(item.maximumLimit)}</td>
                            <td>{formatMoney(item.liabilityLimit)}</td>
                            <td>{getDateTimeFormate(item.startDate)}</td>
                            <td>
                              {item.endDate === "NaN/NaN/NaN 12:NaN AM" ||
                                item.endDate === "NaN/NaN/NaN 12:NaN PM"
                                ? ""
                                : getDateTimeFormate(item.endDate)}
                            </td>
                            <td>{item.tagName}</td>
                            <td>{formatMoney(item.maxStake)}</td>
                            <td>{item.timeToLimit}</td>
                            <td>
                              <span
                                className="material-icons edit pointer"
                                style={{ color: "red" }}
                                onClick={() => onDelete(ind)}
                              >
                                delete
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="mt-30"></div>
            <div className="row">
              <div className="col-12">
                <div className="CMS-btnContainer">
                  <button
                    onClick={() => onAdd()}
                    type={"button"}
                    className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
                  >
                    Add
                  </button>
                  <button
                    onClick={() => onCreate()}
                    className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
                    type={"button"}
                  >
                    Create
                  </button>
                  <button
                    onClick={() => onCancel()}
                    className="CMS-btn CMS-btnQuaternary active CMS-btnMedium"
                    type={"button"}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-30"></div>
          <div className="row">
            <div className="d-flex col-md-4 col-lg-3">
              <input
                type="text"
                placeholder="Search with Tag or Status"
                id="myInput"
                name="searchData"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />

              <span
                className="material-icons search search-icon-AFF pointer"
                onClick={() => onSearchHandle()}
              >
                {COMMON_CONSTANTS.search_icon}
              </span>
            </div>
          </div>

          <div className="CMS-filter-result" id="result">
            <div className="CMS-pagination">
              <Vpagination
                data={allBoostedOdds}
                totalRecords={totalRecord}
                paginationFirstValue={firstPageVal}
                paginationSecondValue={secondPageVal}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={recordToShow}
                setRecordsShow={setRecordToShow}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Boosted Odds Records"}
                reloadRequired={false}
              />
            </div>
          </div>

          <div className="mt-30"></div>
          <div className="CMS-box">
            <div className="CMS-table CMS-table-triped">
              <table>
                <thead>
                  <tr>
                    {tbHeaders.map((item, index) => (
                      <th>
                        <div className="d-flex justify-content-center align-items-center">
                          <p className="mx-3">{item}</p>
                          <Tooltips info={`${headerInfo[index]}`} />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sortedBoostedOdds && sortedBoostedOdds.length > 0 ? (
                    sortedBoostedOdds.map((item, ind) => {
                      return (
                        <tr>
                          <td>{item.sportName}</td>
                          <td>{item.countryName}</td>
                          <td>{item.leagueName}</td>
                          <td>{item.marketName}</td>
                          <td>{item.outComeName}</td>
                          <td>{item.odds}</td>
                          <td>{item.boostedPercentage}</td>
                          <td>{item.boostedOddsValue}</td>
                          <td>{formatMoney(item.minimumLimit)}</td>
                          <td>{formatMoney(item.maximumLimit)}</td>
                          <td>{formatMoney(item.liabilityLimit)}</td>
                          <td>{item.remainingLiability}</td>
                          <td>{getDateTimeFormate(item.startDate)}</td>
                          <td>{getDateTimeFormate(item.endDate)}</td>
                          <td>{item.tagName}</td>
                          <td>{formatMoney(item.maxStake)}</td>
                          <td>{item.timeToLimit}</td>
                          <td>
                            <button
                              className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                              id="onArtworkViewBtn"
                              onClick={() => onViewBtn(item.id)}
                            >
                              <p>View</p>
                            </button>
                          </td>
                          <td>
                            <div className="CMS-file-type CMS-select">
                              <select
                                name={"action"}
                                className={"pointer"}
                                value={action[item.id]}
                                onChange={(e) => handleChange(e, item.id)}
                                defaultValue={item.status}
                                disabled={
                                  item.status === "EXPIRED" ? true : false
                                }
                              >
                                <option value={"ACTIVE"}>Active</option>
                                <option value={"IN_ACTIVE"}>Deactive</option>
                              </select>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <No__Data__Found colspan={50} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoostedOdds;
