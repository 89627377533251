import Dashboard from "./components/Dashboard/dashboard";
import OperationsReport from "./components/Operations/OperationsReport";
import operationSummary from "./components/Operations/operationSummary";
import PlayerSearch from "./components/Players/playersSearch";
import Reports from "./components/Reports/Reports";
import NewPlayer from "./components/NewPlayer/newPlayer";
import PlayerActivity from "./components/PlayerActivity/playerActivity";
import KycUsers from "./components/KycUsers/KycUsers";
import PlayerReferrals from "./components/PlayerReferrals/PlayerReferrals";
import PlayerTagging from "./components/PlayerTagging/PlayerTagging";
import AssignTags from "./components/AssignTags/AssignTags";
import AssignBounus from "./components/AssignBonus/AssignBouns";
import BonusTicker from "./components/BonusTicker/BonusTicker";
import PaymentSearch from "./components/PaymentSearch/paymentSearch";
import PromotionList from "./components/PromotionList/PromotionList";
import CreatePromoMsg from "./components/CreatePromoMsg/CreatePromoMsg";
import ArbitrageBet from "./components/RiskManagement/ArbitrageBet";
import SyndicateBet from "./components/RiskManagement/SyndicateBet";
import UsersMacIP from "./components/RiskManagement/UsersMacIP";
import UsersIp from "./components/RiskManagement/UsersIp";
import CreateBrand from "./components/CreateBrand/createbrand";
import CreateClient from "./components/CreateClient/createclient";
import BrandKey from "./components/BrandKey/BrandKey";
import BrandConfig from "./components/Brand config/BrandConfig";
import CurrencyConvert from "./components/CurrencyConversion/CurrencyConvert";
import SubAffiliate from "./components/Affiliate/SubAffiliate";
import Affiliate from "./components/Affiliate/Affiliate";
import PaymentTicker from "./components/paymentTicker/PaymentTicker";
import pendingDeposits from "./components/PendingDeposits/pendingDeposits";
import pendingWithrawalsSP from "./components/PendingWithrawals/pendingWithrawalsSP";
import PendingWithrawals from "./components/PendingWithrawals/pendingWithrawals";
import WithdrawalLimit from "./components/WithdrawalLimits/WithdrawalLimit";
import PaymentLimits from "./components/paymentLimits/PaymentLimits";
import Depositproof from "./components/DepositProof/Depositproof";
import BetTicker from "./components/BetTicker/betTicker";
import BetHistory from "./components/BetHistory/betHistory";
import MultibetConfig from "./components/Multibet/MultibetConfig";
import MultibetAccount from "./components/Multibet/MultibetAccount";
import CreateUser from "./components/CreateUser/CreateUser";
import UserPermission from "./components/UserPermission/UserPermission";
import CreatePermission from "./components/CreatePermission/CreatePermission";
import BecomeAnAfiliate from "./components/Affiliate/BecomeAfiliate";
import createbonusnew from "./components/createbonusnew/createbonusnew";
import SportsReport from "./components/Reports/sportsReport/SportsReport";
import Withdrawal from "./components/Reports/withdrwalReport/Withdrawal";
import DepositReport from "./components/Reports/DepositReport/DepositReport";
import PlaceBetReport from "./components/Reports/PlaceBetReport/PlaceBetReport";
import LiabilityReport from "./components/Reports/Liability Report/LiabilityReport";
import AffiliateSummary from "./components/AffiliateSummary/AffiliateSummary";
import AccountMsg from "./components/CreateAccountMsg/AccountMsg";
import SystemMsg from "./components/CreateSystemMsg/SystemMsg";
import BoostedOdds from "./components/BoostedOdds/BoostedOdds";
import ActivePlayer from "./components/ActivePlayer/ActivePlayer";
import UsersWatchlist from './components/UsersWatchlist/UsersWatchlist'
import newDashboard from "./components/NewDashboard/newDashboard";
import BetConfg from "./components/CyrusUI/BetConfg";
import BetSettlement from "./components/CyrusUI/BetSettlement";
import BetDetails from "./components/CyrusUI/BetDetails";
import RegistrationTicker from "./components/RegistrationTicker/RegistrationTicker";
import ViewCombi from "./components/CombiSuggestions/ViewCombi";
import Combisuggestions from "./components/CombiSuggestions/Combisuggestions";
import FailedReports from "./components/FailedReports/FailedReports";
import GamesBetHistory from "./components/GamesBetHistory/GamesBetHistory";
import CreateWatchList from "./components/CreateWatchList/CreateWatchList";
import VipReports from "./components/VipReports/VipReports";
import SportsTaxes from "./components/Taxation/SportsTaxes";
import GamesTaxation from "./components/Taxation/GamesTaxation";

export const pages = [
    {
        path: "/dashboard",
        permissionName: "REPORTING_DASHBOARD",
        Component: Dashboard
    },
    {
        path: "/newdashboard",
        permissionName: "REPORTING_NEW_DASHBOARD",
        Component: newDashboard
    },
    {
        path: "/report",
        permissionName: "REPORTING_REPORTS",
        Component: Reports
    },
    {
        path: "/report/withdrawalreport",
        permissionName: "REPORTS_WITHDRAWAL",
        Component: Withdrawal
    },
    {
        path: "/report/depositreport",
        permissionName: "REPORTS_DEPOSIT",
        Component: DepositReport
    },
    {
        path: "/report/placebetreport",
        permissionName: "REPORTS_PLACEBET",
        Component: PlaceBetReport
    },
    {
        path: "/report/liabilityreport",
        permissionName: "REPORTS_LIABILITY",
        Component: LiabilityReport
    },
    {
        path: "/operationSummary",
        permissionName: "OPERATION_SUMMARY",
        Component: operationSummary
    },
    {
        path: "/operationReport",
        permissionName: "OPERATION_REPORT",
        Component: OperationsReport
    },
    {
        path: "/vip-reports",
        permissionName: "VIP_BI_REPORTS",
        Component: VipReports
    },
    {
        path: "/falied-reports",
        permissionName: "ADMIN_FAILED_REPORTS",
        Component: FailedReports
    },
    {
        path: "/playersearch",
        permissionName: "PLAYERS_SEARCH",
        Component: PlayerSearch
    },
    {
        path: "/newplayer",
        permissionName: "PLAYERS_NEW",
        Component: NewPlayer
    },
    {
        path: "/playerActivity",
        permissionName: "PLAYER_ACTIVITY",
        Component: PlayerActivity
    },
    {
        path: "/kycusers",
        permissionName: "PLAYERS_KYC_VERIFICATION",
        Component: KycUsers
    },
    {
        path: "/playerreferrals",
        permissionName: "PLAYERS_REFERRALS",
        Component: PlayerReferrals
    },
    {
        path: "/activeplayer",
        permissionName: "ADMIN_ACTIVE_PLAYERS",
        Component: ActivePlayer
    },
    {
        path: "/userswatchlist",
        permissionName: "ADMIN_USERS_WATCHLIST",
        Component: UsersWatchlist
    },
    {
        path: "/registrationTicker",
        permissionName: "REGISTRATION_TICKER",
        Component: RegistrationTicker
    },
    {
        path: "/createTags",
        permissionName: "TAG_CREATE",
        Component: PlayerTagging
    },
    {
        path: "/AssignTags",
        permissionName: "TAG_ASSIGN",
        Component: AssignTags
    },
    {
        path: "/createbonus",
        permissionName: "BONUS_CREATE",
        Component: createbonusnew
    },
    {
        path: "/assignbonus",
        permissionName: "BONUS_APPLY",
        Component: AssignBounus
    },
    {
        path: "/bonusTicker",
        permissionName: "BONUS_TICKER",
        Component: BonusTicker
    },
    {
        path: "/boostedodds",
        permissionName: "BOOSTED_ODDS",
        Component: BoostedOdds
    },
    {
        path: "/paymentsearch",
        permissionName: "PAYMENT_SEARCH",
        Component: PaymentSearch,
    },
    {
        path: "/paymentticker",
        permissionName: "PAYMENT_TICKER",
        Component: PaymentTicker
    },
    {
        path: "/pendingdeposits",
        permissionName: "PAYMENT_PENDING_DEPOSIT",
        Component: pendingDeposits
    },
    {
        path: "/pendingwithdrawalsoffline",
        permissionName: "PAYMENT_PENDING_WITHDRAWALS_OFFLINE",
        Component: pendingWithrawalsSP
    },
    {
        path: "/pendingwithdrawals",
        permissionName: "PAYMENT_PENDING_WITHDRAWAL",
        Component: PendingWithrawals
    },
    {
        path: "/withdrawallimit",
        permissionName: "PAYMENT_WITHDRAWAL_LIMIT",
        Component: WithdrawalLimit
    },
    {
        path: "/paymentLimits",
        permissionName: "PAYMENT_LIMITS",
        Component: PaymentLimits
    },
    {
        path: "/depositproof",
        permissionName: "PAYMENTS_DEPOSIT_PROOF",
        Component: Depositproof
    },
    // {
    //     path: "/sports-taxes",
    //     permissionName: "SPORTS_TAXES",
    //     Component: SportsTaxes
    // },
    // {
    //     path: "/games-taxes",
    //     permissionName: "GAMES_TAXES",
    //     Component: GamesTaxation
    // },
    {
        path: "/betHistory",
        permissionName: "BETTING_HISTORY",
        Component: BetHistory
    },
    {
        path: "/gamesBetHistory",
        permissionName: "GAMES_BET_HISTORY",
        Component: GamesBetHistory
    },
    {
        path: "/betTicker",
        permissionName: "BETTING_TICKER",
        Component: BetTicker
    },
    {
        path: "/multibetconfiguration",
        permissionName: "BETTING_MULTIBET_CONFIG",
        Component: MultibetConfig
    },
    {
        path: "/multibetaccount",
        permissionName: "BETTING_MULTIBET_ACCOUNTING",
        Component: MultibetAccount
    },
    {
        path: "/combisuggestions",
        permissionName: "ADMIN_COMBI_SUGGESTIONS",
        Component: Combisuggestions
    },
    {
        path: "/viewcombi",
        permissionName: "ADMIN_VIEW_COMBI",
        Component: ViewCombi
    },
    {
        path: "/createuser",
        permissionName: "ADMIN_USER",
        Component: CreateUser
    },
    {
        path: "/createrole",
        permissionName: "ADMIN_ROLE",
        Component: UserPermission
    },
    {
        path: "/createpermission",
        permissionName: "ADMIN_PERMISSION",
        Component: CreatePermission
    },
    {
        path: "/becomeaffiliate",
        permissionName: "ADMIN_BECOME_AFFILIATE",
        Component: BecomeAnAfiliate
    },
    {
        path: "/affiliate",
        permissionName: "ADMIN_AFFILIATE",
        Component: Affiliate

    },
    // {
    //     path: "/subaffiliate",
    //     permissionName: "ADMIN_SUB_AFFILIATE",
    //     Component: SubAffiliate
    // },
    {
        path: "/currncyconversion",
        permissionName: "ADMIN_CURRENCY_CONVERSION",
        Component: CurrencyConvert
    },
    {
        path: "/brandconfig",
        permissionName: "BRAND_CONFIG",
        Component: BrandConfig
    },
    {
        path: "/brandkey",
        permissionName: "BRAND_KEYS",
        Component: BrandKey
    },
    {
        path: "/createclient",
        permissionName: "ADMIN_CREATE_CLIENT",
        Component: CreateClient
    },
    {
        path: "/createbrand",
        permissionName: "ADMIN_CREATE_BRAND",
        Component: CreateBrand
    },
    {
        path: "/usersIp",
        permissionName: "RISK_MANAGMEMENT_USERS_SAME_IP",
        Component: UsersIp
    },
    {
        path: "/usersMac&Ip",
        permissionName: "RISK_MANAGMEMENT_USERS_SAME_ADDRESS",
        Component: UsersMacIP
    },
    {
        path: "/syndicateBet",
        permissionName: "RISK_MANAGMEMENT_SYNDICATE_BETTING",
        Component: SyndicateBet
    },
    {
        path: "/userswithsamedetails",
        permissionName: "RISK_MANAGMEMENT_ARBITRAGE_BETTING",
        Component: ArbitrageBet
    },
    {
        path: "/createpromomsg",
        permissionName: "ADMIN_COMMUNICATION_CREATEPROMO",
        Component: CreatePromoMsg
    },
    {
        path: "/promotionlist",
        permissionName: "ADMIN_COMMUNICATION_PROMOLIST",
        Component: PromotionList
    },
    {
        path: "/affiliatesummary",
        permissionName: "ADMIN_AFFILIATE_SUMMARY",
        Component: AffiliateSummary
    },
    {
        path: "/createaccountmsg",
        permissionName: "ADMIN_COMMUNICATION_CREATEPROMO",
        Component: AccountMsg
    },
    {
        path: "/createsystemmsg",
        permissionName: "ADMIN_COMMUNICATION_CREATEPROMO",
        Component: SystemMsg
    },
    {
        path: "/betconfig",
        permissionName: "CYRUS_BET_CONFIG",
        Component: BetConfg
    },
    {
        path: "/betsettlement",
        permissionName: "CYRUS_BET_SETTLEMENT",
        Component: BetSettlement
    },
    {
        path: "/betdetails",
        permissionName: "CYRUS_BET_DETAILS",
        Component: BetDetails
    },
    {
        path: "/createwatchlist",
        permissionName: "ADMIN_CREATE_WATCHLIST",
        Component: CreateWatchList
    },

]