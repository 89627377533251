import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import sidebarReducer from "../components/sidebar/sidebarSlice";
import reportReducer from "../components/Reports/reportSlice";
import appSliceReducer from "../components/AppSlice";
import playerSearchReducer from "../components/playerTable/playerSearchTableSlice";
import generalDetailReducer from "../components/GeneralDetail/GeneralDetailSlice";
import loginHistoryReducer from "../components/LoginHistory/loginHistoryTableSlice";
import paymentHistoryReducer from "../components/paymentHistory/paymentHistoryTableSlice";
import AccountHistoryReducer from "../components/AccountHistory/accountHistoryslice";
import DashboardReducer from "../components/Dashboard/dashboardSlice";
import LoginReducer from "../components/Login/loginSlice";
import PrimaryPlayerReducer from "../components/primaryplayer/PrimaryPlayerSlice";
import NewPlayerReducer from "../components/NewPlayer/NewPlayerSlice";
import BetHistorySliceReducer from "../components/BetHistory/betHistorySlice";
import PlayerActivityReducer from "../components/PlayerActivity/playerActivitySlice";
import PaymentSearchReducer from "../components/PaymentSearch/paymentSearchSlice";
import AddNoteReducer from "../components/AddNote/addNoteSlice";
import PaymentTickerReducer from "../components/paymentTicker/paymentTickerSlice";
import PendingDepositSlice from "../components/PendingDeposits/pendingDepositSlice";
import PendingWithrawalSlice from "../components/PendingWithrawals/pendingWithrawalSlice";
import BettingTickerReducer from "../components/BetTicker/bettingTickerSlice";
import playerTaggingSlice from "../components/PlayerTagging/PlayerTaggingSlice";
import TagsSliceReducer from "../components/Tags/TagSlice";
import PlayerBetHistorySlice from "../components/BettingHistory/playerBetHistorySlice";
import AssignTagSlice from "../components/AssignTags/AssignTagSlice";
import AssignBonusSlice from "../components/AssignBonus/AssignBonusSlice";
import Bonus from "../components/createbonusnew/createbonusnewslice";
import BankDetailSlice from "../components/BankDetails/BankDetailSlice";
import BrandConfigSlice from "../components/Brand config/BrandConfigSlice";
import BonusTransactionSlice from "../components/BonusTransaction/bonusTransactionSlice";
import CreateRoleSlice from "../components/UserPermission/createRoleSlice";
import CreateUserSlice from "../components/CreateUser/createUserSlice";
import FraudScrubSlice from "../components/FraudScrub/fraudSlice";
import AffiliateSlice from "../components/Affiliate/AffiliateSlice";
import MultibetSlice from "../components/Multibet/MultibetSlice";
//import BetAuditSlice from "../components/BetAudit/betAuditSlice";
import CreateClientSlice from "../components/CreateClient/createclientslice";
import CreateBrandSlice from "../components/CreateBrand/createbrandslice";
import CreatePermission from "../components/CreatePermission/CreatePermissionSlice";
import CurrencySlice from "../components/CurrencyConversion/Currencyslice"
import PaymentLimits from "../components/paymentLimits/paymentLimitSlice"
import ResponsibleGambling from "../components/ResponsibleGambling/GamblingLimitsSlice"
import BonusSlice from "../components/Bonuses/BonusSlice";
import KycUserSlice from "../components/KycUsers/KycUserSlice";
import riskManagementSlice from "../components/RiskManagement/RiskmgmtSlice";
import paymentLimitTab from "../components/PaymentLimitTab/LimitsTabSlice";
import UserStakeSlice from "../components/UserStakeSetting/UserStakeSlice";
import BetTickerSlice from "../components/BonusTicker/bonusTickerSlice";
import bireportSlice from "../components/Reports/BiReports/bireportSlice";
import bidashboardSlice from "../components/BiDashboard/biDashSlice"
import DepositproofSlice from "../components/DepositProof/DepositproofSlice";
import PlayerRefSlice from "../components/PlayerReferrals/PlayerRefSlice"
import PromoListSlice from "../components/PromotionList/promotionListSlice";
import CreatePromoSlice from "../components/CreatePromoMsg/CreatePromoSlice";
import AffiliateSummarySlice from "../components/AffiliateSummary/AffSummarySlice";
import TemplateSlice from "../components/CreateAccountMsg/TemplateSlice";
import BoostedOddsSlice from "../components/BoostedOdds/boostedOddsSlice";
import UserWatchListSlice from "../components/UsersWatchlist/userWatchListSlice";
import NewDashboardSlice from "../components/NewDashboard/newDashboardSlice";
import RegTickerSlice from "../components/RegistrationTicker/RegTickerSlice";
import CyrusUiSlice from "../components/CyrusUI/CyrusUiSlice";
import combiSlice from "../components/CombiSuggestions/combiSlice";
import FailedReportSlice from "../components/FailedReports/FailedReportSlice";
import GamesBetHistorySlice from "../components/GamesBetHistory/GamesHistorySlice";
import CreateWatchListSlice from "../components/CreateWatchList/watchLIstSlice";
import KycDocumentsSlice from "../components/KYCDocuments/KycDocSlice";
// import  TaxationSlice  from "../components/Taxation/TaxationSlice";
export default configureStore({
  reducer: {
    sidebar: sidebarReducer,
    reports: reportReducer,
    appslice: appSliceReducer,
    playersearch: playerSearchReducer,
    generaldetail: generalDetailReducer,
    loginHistorySlice: loginHistoryReducer,
    paymentHistorySlice: paymentHistoryReducer,
    accountHistory: AccountHistoryReducer,
    dashboard: DashboardReducer,
    loginSlice: LoginReducer,
    newplayer: NewPlayerReducer,
    primaryplayer: PrimaryPlayerReducer,
    betHistorySliceReducer: BetHistorySliceReducer,
    playeractivity: PlayerActivityReducer,
    paymentSearchSlice: PaymentSearchReducer,
    addNotesSlice: AddNoteReducer,
    paymentticker: PaymentTickerReducer,
    pendingWithrawal: PendingWithrawalSlice,
    pendingDeposit: PendingDepositSlice,
    bettingticker: BettingTickerReducer,
    playertagging: playerTaggingSlice,
    tags: TagsSliceReducer,
    playerBetHistorySlice: PlayerBetHistorySlice,
    assignTagSlice: AssignTagSlice,
    assignBonusSlice: AssignBonusSlice,
    bonus: Bonus,
    bankDetails: BankDetailSlice,
    brandconfig: BrandConfigSlice,
    bonusTransactionSlice: BonusTransactionSlice,
    createRole: CreateRoleSlice,
    createuserSlice: CreateUserSlice,
    fraudscrub: FraudScrubSlice,
    // betAuditSlice: BetAuditSlice,
    affiliateSlice: AffiliateSlice,
    multibetconfig: MultibetSlice,
    createclient: CreateClientSlice,
    createbrand: CreateBrandSlice,
    createPermission: CreatePermission,
    currencyconversion: CurrencySlice,
    paymentLimit: PaymentLimits,
    responsiblegambling: ResponsibleGambling,
    bonusSlice: BonusSlice,
    kycusers: KycUserSlice,
    riskManagement: riskManagementSlice,
    paymentLimitTab: paymentLimitTab,
    UserStakeSlice: UserStakeSlice,
    BetTickerSlice: BetTickerSlice,
    bireportSlice: bireportSlice,
    bidashboardSlice: bidashboardSlice,
    DepositproofSlice: DepositproofSlice,
    PlayerRefSlice: PlayerRefSlice,
    PromoListSlice: PromoListSlice,
    CreatePromoSlice: CreatePromoSlice,
    AffiliateSummarySlice: AffiliateSummarySlice,
    TemplateSlice: TemplateSlice,
    BoostedOddsSlice: BoostedOddsSlice,
    UserWatchListSlice: UserWatchListSlice,
    NewDashboardSlice: NewDashboardSlice,
    registrationTicker: RegTickerSlice,
    cyrusuislice: CyrusUiSlice,
    combiSlice: combiSlice,
    FailedReportSlice: FailedReportSlice,
    GamesBetHistorySlice: GamesBetHistorySlice,
    CreateWatchListSlice: CreateWatchListSlice,
    KycDocumentsSlice: KycDocumentsSlice,
    // taxation: TaxationSlice,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      ignoreActions: true,
    }),
  ],
});
