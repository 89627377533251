import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  MomentUTCDateTimeEnd,
  MomentUTCDateTimeToday,
  formatMoney,
  getDateTimeFormate,
  getDecimalNumber,
  getUTCDate,
  regexType,
  setToLocalStorage,
} from "../../sharedfiles/helper";

import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import {
  AffiliateData,
  AffiliateDropdown,
  AfftbHeader,
  affilatePayData,
  affiliateDates,
} from "../Common/data/mapData";
import {
  createAffiliate,
  getAffiliateTable,
  editAgent,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisible,
  setRecordsShow,
  setOpenDialog,
  getUpdateUser,
  setDocsData_aff,
  setFormData_aff,
  setFormErrors_aff,
  setResData_suboff,
  setSelected_AffiliateId,
  setCampaignList,
  setChannel,
  setAffiliateUsers,
  getAffArtwork,
  artWorkUpload,
  setDisableBtn,
  getSearchAffData,
  setAffiliateList,
  setIsAffChannel,
  setIsChecked,
  AffilatePay,
  GetBalanceAmount
} from "./AffiliateSlice";
import Select from "../Common/common_inputs/Select_DDL";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Skelton from "../Common/skelton/Skelton";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import EditUser from "../Common/Dialog/EditUser";
import { getUserDetails } from "../CreateUser/createUserSlice";
import AgentEditDialog from "./AgentEditDialog";
import {
  AFFILIATE_CONSTANTS,
  COMMON_CONSTANTS,
  CREATEROLE_CONSTANTS,
  KYC_CONSTANTS,
  MANUALDIALOG_CONSTANTS
} from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { Admin, AffiliateInfo, COMMON_MSGS } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import Datepicker from "../Common/common_inputs/DatePicker";

const Affiliate = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [AffiliateId, setAffiliateId] = useState("");
  const [seletedItem, setSelectedItem] = useState({});
  const [pdfBody, setPdfBody] = useState([]);
  const [searchData, setSearchData] = useState("")
  const [formdata, setFormData] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    loginPin: "",
    // clientId: "",
    // btag: "",
    // role: "ADMIN",
    commissionPercentage: "",
    payoutCycle: "",
    setupCost: "",
    affiliateType: "",
  });
  const [affiliateInput, setAffiliateInput] = useState({
    payoutCycle: "",
    setupCost: "",
    commissionPercentage: "",
  });
  const [agentEditError, setAgentEditError] = useState([]);
  const [editUserBox, setEditUserBox] = useState(false);
  const [artworkfile, setArtworkfile] = useState('')
  const [fileErr, setFileErr] = useState(false)
  const [disable, setDisable] = useState(false)
  const [dateFields, setDateFields] = useState({
    regDateFrom: '',
    regDateTo: '',
  })
  const [dateErr, setDateErr] = useState("")
  const [openPay, setOpenPay] = useState("")
  const [payFields, setPayFields] = useState({
    amount: "",
    description: '',
  })
  const [userName, setUserName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const history = useHistory();
  const pageFirstVal = useSelector(
    (state) => state.affiliateSlice.paginationFirstValue
  );
  const pageSecondVal = useSelector(
    (state) => state.affiliateSlice.paginationSecondValue
  );
  const recordsToShow = useSelector(
    (state) => state.affiliateSlice.recordsShow
  );
  const listAffiliate = useSelector((state) => state.affiliateSlice.resData);
  const isChecked = useSelector((state) => state.affiliateSlice.isChecked);
  const getBalanceAmount = useSelector((state) => state.affiliateSlice.getBalanceAmount);
  const affGgrNgrPermission = useSelector((p) => p.dashboard.affiliate_GGR_NGR)
  const affCommissionPermission = useSelector((p) => p.dashboard.affCommission)
  const afiilatePay = useSelector((p) => p.dashboard.afiilatePay)
  const permissionToEditUserDetails = props.permissionToEditUserDetails;
  const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)

  const filteredHeader = AfftbHeader.filter((header) => {
    if (header === "Edit User") {
      return permissionToEditUserDetails;
    } else if (header === "NGR" || header === "GGR") {
      return affGgrNgrPermission;
    } else if (header === 'Setup Cost' || header === "Gross Commission" || header === "Net Commission") {
      return affCommissionPermission
    }
    else if (header === "Pay") {
      return afiilatePay;
    }
    else {
      return true; // Include all other headers
    }
  });
  const affiliateTooltip = AffiliateInfo.filter((ele, id) => id !== 9 && id !== 10 && id !== 8)

  const filedsHandler = (e, status) => {
    const { name, value } = e.target;

    const newError = error.filter((err) => name !== err);
    setError(newError);
    if (status == "dialog") {
      setAffiliateInput((data) => {
        return { ...data, [name]: value };
      });
      if (value === "") {
        setAgentEditError([...agentEditError, name]);
      } else {
        const isErrorsExist = agentEditError.length > 0;
        if (isErrorsExist) {
          const updated = agentEditError.filter((err) => err !== name);
          setAgentEditError(updated);
        }
      }
    }
    if (status == "input") {
      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
      dispatch(setFormData_aff({ ...props.formData_aff, [name]: value }));

      if (value === "") {
        setError([...error, name]);
        dispatch(setFormErrors_aff([...props.formErrors_aff, name]));
      } else {
        const isErrorsExist = error.length > 0;
        if (isErrorsExist) {
          const updatedErrors = error.filter((err) => err !== name);
          setError(updatedErrors);
          dispatch(setFormErrors_aff(updatedErrors));
        }
      }
    }
  };

  const validator = () => {
    const inputValue = Object.keys(formdata);
    const err = [];
    inputValue.map((value) => formdata[value] === "" && err.push(value));
    setError(err);
    dispatch(setFormErrors_aff(err));
    return err.length === 0;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validator()) {
      dispatch(createAffiliate(formdata));

      setTimeout(() => {
        dispatch(
          getAffiliateTable(
            props.paginationFirstValue - 1,
            props.paginationSecondValue
          )
        );
        resetButton();
      }, 500);
    }
  };
  const resetButton = (e) => {
    setFormData({
      userName: "",
      firstName: "",
      lastName: "",
      loginPin: "",
      // clientId: "",
      // btag: "",
      // role: "ADMIN",
      commissionPercentage: "",
      payoutCycle: "",
      setupCost: "",
      affiliateType: "",
    });
    setDateErr('')
    setError([]);
    setAgentEditError([]);
    dispatch(setFormData_aff({}));
    dispatch(setFormErrors_aff([]));
  };

  useEffect(() => {
    const dataExist = Object.keys(props?.resData).length > 0;
    if (!dataExist) {
      // dispatch(
      //   getAffiliateTable(
      //     props.paginationFirstValue - 1,
      //     props.paginationSecondValue,
      //   )
      // );
      const payload = {
        subAffliate: true
      }
      dispatch(getSearchAffData(props.paginationFirstValue - 1, props.paginationSecondValue, payload));
      dispatch(setFormData_aff(formdata));
    } else {
      const csvDataExist =
        props?.docsData_aff &&
        Array.isArray(props.docsData_aff) &&
        props.docsData_aff.length > 0;
      let pdfData = [];
      if (csvDataExist) {
        pdfData = props?.docsData_aff.slice(1, props?.docsData_aff?.length);
      }
      setCsvData(props.docsData_aff);
      setPdfBody(pdfData);

      setFormData(props.formData_aff);
      setError(props.formErrors_aff);
    }
  }, []);

  const handleActiveDeactive = (item, status) => {
    const data = {
      userId: item.id,
      obj: {
        firstName: item.firstName,
        lastName: item.lastName,
        // "platFormClient": "string",
        status: status,
        userName: item.userName,
      },
    };
    dispatch(getUpdateUser(data));
    dispatch(
      getAffiliateTable(
        props.paginationFirstValue - 1,
        props.paginationSecondValue,
      )
    );
  };

  const handleDialog = (item) => {
    setAffiliateId(item.id);
    setSelectedItem(item);
    setAffiliateInput({
      payoutCycle: item.payoutCycle,
      setupCost: item.setupCostPercentage,
      commissionPercentage: item.commissionPercentage,
    });
    setAgentEditError([]);

    props.dispatch(setOpenDialog(true));
  };

  const dialogFieldValuator = () => {
    const inputValue = Object.keys(affiliateInput);
    const err = [];

    inputValue.map((value) => affiliateInput[value] === "" && err.push(value));
    setAgentEditError(err);
    return err.length === 0;
  };

  const onSave = (e) => {
    if (dialogFieldValuator()) {
      const agentData = {
        affiliateId: AffiliateId,
        commissionPercentage: affiliateInput.commissionPercentage,
        payoutCycle: affiliateInput.payoutCycle,
        setupCost: affiliateInput.setupCost,
      };

      props.dispatch(setOpenDialog(false));
      dispatch(editAgent(agentData));
      setTimeout(() => {
        dispatch(
          getAffiliateTable(
            props.paginationFirstValue - 1,
            props.paginationSecondValue,
          )
        );
      }, 500);
    }
  };
  const headers = [
    [
      "ID",
      "User Name",
      "First Name",
      "Last Name",
      "Affiliate Type",
      "Btag",
      "Payout Cycle",
      "Setup Cost %",
      "Setup Cost",
      "Gross Commission",
      "Net Commission",
      "Commission %",
      "Total Registrations",
      "Deposited Customers",
      "Total Deposits",
      "Total Stakes",
      "Total Payout",
      "GGR",
      "NGR",
      "Total Bonus Amount",
      "Total Number of Bets",
      "Total Withdrawals",
      "First Time Deposited Customer",
      "Created Date",
      "Updated Date",
      "URL Hits Count"
    ],
  ];
  const headersToExport = headers[0].filter((header) => {
    if (header === "Edit User") {
      return permissionToEditUserDetails;
    } else if (header === "NGR" || header === "GGR") {
      return affGgrNgrPermission;
    } else if (header === 'Setup Cost' || header === "Gross Commission" || header === "Net Commission") {
      return affCommissionPermission
    } else {
      return true; // Include all other headers
    }
  });
  const exportPDF = () => {
    const title = "Affiliate Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };

    exportPDFC(title, [headersToExport], pdfBody, styles);
  };

  useEffect(() => {
    if (Object.keys(props.resData).length > 0) {
      const data =
        props.resData &&
          props.resData?.data &&
          Array.isArray(props.resData?.data)
          ? props.resData?.data?.map((p) => [
            p.id,
            p.userName,
            p.firstName,
            p.lastName,
            p.affiliateTypes,
            p.btag,
            p.payoutCycle,
            p.setupCostPercentage,
            ...(affCommissionPermission ? [formatMoney(p.setupCost), formatMoney(p.grossCommission), formatMoney(p.netCommission)] : []),
            p.commissionPercentage,
            p.totalRegistrations,
            p.depositedUserCounts,
            formatMoney(p.totalDeposit),
            formatMoney(p.totalStake),
            formatMoney(p.totalPayout),
            ...(affGgrNgrPermission ? [formatMoney(p.totalGgr), formatMoney(p.totalNgr)] : []),
            formatMoney(p.totalWithdraw),
            p.totalBonus,
            p.betCount,
            p.firstTimeDepositedCustomers,
            p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
            p.updatedDate ? getDateTimeFormate(p.updatedDate) : "-",
            p.affliateClickCount,
          ])
          : [];

      setPdfBody(data);
      const csvData =
        data && Array.isArray(data) && data.length > 0
          ? [headersToExport, ...data]
          : [headersToExport, []];
      setCsvData(csvData);
      dispatch(setDocsData_aff(csvData));
    }
  }, [props.resData]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    const fromDate = dateFields.regDateFrom ? MomentUTCDateTimeToday(dateFields.regDateFrom) : '';
    const toDate = dateFields.regDateTo ? MomentUTCDateTimeEnd(dateFields.regDateTo) : '';

    const payload = {
      startDate: fromDate,
      endDate: toDate,
      subAffliate: isChecked,
    };

    props.dispatch(setIsVisible(false));
    dispatch(getSearchAffData(pageNumber - 1, itemsPerPage, payload));
  };


  function navigateHandler(title, page, item) {
    setToLocalStorage("AffliateUserId", item.id);
    setToLocalStorage("AffUserBtag", item.btag);
    dispatch(setSelected_AffiliateId(item.id));
    dispatch(setAffiliateUsers(item.btag))
    title === "CAMPAIGN" && dispatch(setCampaignList([]));
    title === "CHANNEL" && dispatch(setChannel([]));
    if (page === "subaffiliate") dispatch(setResData_suboff([]));
    dispatch(setIsAffChannel(false))
    history.push(`/${page}`);
  }

  useEffect(() => {
    if (props.disableBtn) {
      setTimeout(() => {
        dispatch(setDisableBtn(false))
        setDisable(false)
      }, 3000)
    }
  }, [props.disableBtn])

  const handleEditUser = (item) => {
    dispatch(getUserDetails(item.id));
    setAffiliateId(item.id);
    setSelectedItem(item);
    setEditUserBox((prev) => !prev);
  }
  const handlePay = (phoneNumber) => {
    setOpenPay(true)
    setUserName(phoneNumber)
    dispatch(GetBalanceAmount(phoneNumber))
  }
  const handleOpenPay = () => {
    setOpenPay(false)
  }
  const payhandler = (e) => {
    const { name, value } = e.target;
    setPayFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const submitPay = () => {
    const payload = {
      amount: payFields.amount,
      description: payFields.description,
      userName: userName
    }
    dispatch(AffilatePay(payload))
    setPayFields({ amount: '', description: "" })
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setTimeout(() => {
      dispatch(GetBalanceAmount(userName));
    }, 1000);

  }

  const uploadIp = (e) => {
    const { name, value } = e.target
    setArtworkfile(e.target.files[0])
    if (name === "artworkfile" && value === "") {
      setFileErr(true)
    } else {
      setFileErr(false)
    }
  }

  const onUpload = (e) => {
    e.preventDefault();
    if (artworkfile === "") {
      setFileErr(true)
    }
    if (artworkfile) {
      const fetchFormData = new FormData();
      fetchFormData.append("file", artworkfile);
      dispatch(artWorkUpload(fetchFormData));
      const fileField = document.getElementById("artworkfile")
      if (fileField) {
        fileField.value = ""
        setArtworkfile("")
      }
      setDisable(true)
      setFileErr(false)
    }
  };


  const onCancel = () => {
    setFileErr(false)
    const fileField = document.getElementById("artworkfile")
    if (fileField) {
      fileField.value = ""
      setArtworkfile("")
    }
  }

  const setDateHandler = (value, name) => {
    setDateFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (name === "regDateFrom" && value !== '') {
      setDateErr('')
    }
  };


  const onSearch = () => {
    const payload = {
      endDate: dateFields.regDateTo !== "" ? MomentUTCDateTimeEnd(dateFields.regDateTo) : '',
      startDate: dateFields.regDateFrom !== "" ? MomentUTCDateTimeToday(dateFields.regDateFrom) : "",
      subAffliate: isChecked,
    }
    if (dateFields.regDateFrom === '' || dateFields.regDateFrom === null) {
      setDateErr("Start Date is required");
    } else if (dateFields.regDateFrom !== '') {
      setDateErr('');
      dispatch(setIsVisible(false));
      dispatch(getSearchAffData(0, 25, payload))
    }
  }

  const onResetBtn = () => {
    setDateFields({
      regDateFrom: '',
      regDateTo: ''
    })
    setDateErr('')
    dispatch(getSearchAffData(0, 25, { subAffliate: isChecked }))
    dispatch(setIsVisible(false));
    dispatch(setRecordsShow([1, 25]));
    dispatch(setPaginationFirstValue(1));
    dispatch(setPaginationSecondValue(25));
  }


  function affiliateSearch(value) {
    let payloadData = {
      userName: value,
      subAffliate: isChecked
    }
    dispatch(setAffiliateList(value))
    dispatch(setIsVisible(false));
    dispatch(getSearchAffData(0, 25, payloadData))
  }
  const handleCheckboxChange = (event) => {
    if (!event.target.checked) {
      dispatch(setIsVisible(false))
      dispatch(setIsChecked(event.target.checked))
      const checkedPayload = {
        subAffliate: event.target.checked
      }
      dispatch(getSearchAffData(props.paginationFirstValue - 1, props.paginationSecondValue, checkedPayload));
    }
    else {
      dispatch(setIsVisible(false))
      dispatch(setIsChecked(event.target.checked));
      const checkedPayload = {
        subAffliate: event.target.checked,
      }
      dispatch(getSearchAffData(props.paginationFirstValue - 1, props.paginationSecondValue, checkedPayload));

    }
  };
  return (
    <>
      {
        editUserBox &&
        <Dialog__Main
          title={"Edit User Details"}
          dOpen={editUserBox}
          dClose={setEditUserBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"editUserBox"}
        >
          <EditUser
            open={editUserBox}
            close={setEditUserBox}
            userId={AffiliateId}
            userDetails={seletedItem}
            rolesRequired={false}
            page={"affiliate"}
          />
        </Dialog__Main>
      }
      <Dialog__Main
        dOpen={props.openDialog}
        dClose={() => props.dispatch(setOpenDialog(false))}
        title={"Edit Agent"}
      >
        <AgentEditDialog
          filedsHandler={filedsHandler}
          onSave={onSave}
          agentEditError={agentEditError}
          affiliateInput={affiliateInput}
        />
      </Dialog__Main>
      <Dialog__Main
        title={"Pay"}
        dOpen={openPay}
        dClose={handleOpenPay}
        width={"sm"}
        dHeight={"280px"}
        ids={"pay"}
      >
        <React.Fragment>
          <div className='CMS-box CMS-box-content custom_box_size'>
            <div className="CMS-alert alert-primary">
              <div className="row">
                <div className="col-6 vh_laign_center alert_conetnet">
                  <span>{"Paid Amount"}</span>
                </div>
                <div className="col-6 vh_laign_center">
                  <b>{formatMoney(getBalanceAmount?.paidAmount ? getBalanceAmount?.paidAmount : 0)}</b>
                </div>
                <div className="col-6 vh_laign_center alert_conetnet">
                  <span>{"Balance Amount"}</span>
                </div>
                <div className="col-6 vh_laign_center">
                  <b>{formatMoney(getBalanceAmount?.balanceAmount ? getBalanceAmount?.balanceAmount : 0)}</b>
                </div>

              </div>
            </div >
            <Input
              title={"Amount"}
              name={"amount"}
              value={payFields.amount}
              placeholder={"Amount"}
              setValue={(e) => payhandler(e)}
              ids={"pay"}
              info={"Showing Pay"}
              show={true}
              regex={regexType.numOnly2Digit}
            />
            <Input
              title={"Description"}
              name={"description"}
              value={payFields.description}
              placeholder={"Description"}
              setValue={(e) => payhandler(e)}
              ids={"pay"}
              info={"Showing Description"}
              show={true}
            />
            <div className="col-12">
              <div className='totp_buttons'>
                <button
                  className="totp_btn_enable"
                  type={"button"}
                  onClick={submitPay}
                  disabled={isButtonDisabled}

                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Dialog__Main>
      <div className="CMS-tab-panel active" id="CMS-playerActivity">
        <div className="CMS-tabContent">
          <div className="CMS-box CMS-box-content">
            <div className="row">
              <div className="col-md-4 col-xl">
                <Select
                  title={"Affiliate Type"}
                  name={"affiliateType"}
                  value={formdata.affiliateType}
                  setValue={(val) => filedsHandler(val, "input")}
                  options={AffiliateDropdown}
                  errorMsg={
                    error.length > 0 &&
                    error.includes("affiliateType") &&
                    "select proper Affilate"
                  }
                  ids={"AffiliateType"}
                  info={Admin.Affiliate_Type}
                />
              </div>
              {AffiliateData.map((item, ind) => {
                return (
                  <React.Fragment key={item.id}>
                    <div className="col-3">
                      <Input
                        title={item.title}
                        name={item.name}
                        ids={item.ids}
                        value={formdata[item.name]}
                        placeholder={item.placeholder}
                        setValue={(e) => filedsHandler(e, "input")}
                        regex={item.regex}
                        errorMsg={
                          error.length > 0 &&
                          error.includes(item.name) &&
                          item.errorMsg
                        }
                        info={item.info}
                        show={true}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>


            <div className="mt-20"></div>
            <div className="row">
              <ButtonGroup
                groupNames={[" Create Affiliate", "Reset"]}
                idsearch={"createAffiliate"}
                idreset={"affiliateReset"}
                submit={(e) => onSubmit(e)}
                reset={(e) => resetButton(e)}
                freezButton={viewOnlyuser}
              />
            </div>
          </div>

          <div className="mt-50"></div>
          {props.affArtWork ? <div className="CMS-box CMS-box-content">
            <div className="row mt-20">
              <div className='col-lg-4 col-xl-4 ml-20'>
                <Input
                  title={"Upload Artwork"}
                  placeholder={"upload file"}
                  name={"artworkfile"}
                  type={"file"}
                  ids={"artworkfile"}
                  show={true}
                  info={COMMON_MSGS.Aff_artWork}
                  accept={"image/jpeg,image/png,image/svg,image/tiff"}
                  setValue={(e) => uploadIp(e)}
                  errorMsg={fileErr && "Please choose proper file"}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="CMS-btnContainer">
                <button
                  onClick={(e) => onUpload(e)}
                  className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${(disable || viewOnlyuser) ? 'disable-button' : ''}`}
                  type={"button"}
                  id={"updateLimit"}
                >
                  {COMMON_CONSTANTS.Upload}
                </button>
                <button
                  onClick={(e) => onCancel(e)}
                  className={`CMS-btn CMS-btnQuaternary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}

                  type={"button"}
                  id={"updateLimit"}
                >
                  {COMMON_CONSTANTS.cancel}
                </button>
              </div>
            </div>
          </div> : ''}


          <div className="mt-50"></div>
          <div className="CMS-box CMS-box-content">
            <div className='row'>
              {affiliateDates.map((item, ind) => {
                const minDate = [null, dateFields.regDateFrom];
                const max = dateFields.regDateTo ? dateFields.regDateTo : new Date();
                const maxDate = [max, new Date()];
                const values = [dateFields.regDateFrom, dateFields.regDateTo];
                return (
                  <div className='col-3' key={ind}>
                    <Datepicker
                      title={item.title}
                      name={item.name}
                      value={values[ind]}
                      setValue={(date, value) => setDateHandler(date, value)}
                      errorMsg={ind === 0 ? dateErr : ""}
                      minDate={minDate[ind]}
                      maxDate={maxDate[ind]}
                      ids={item.ids}
                      info={item.info}
                    />
                  </div>
                );
              })}
              <div className="ml-15"></div>
              <ButtonGroup
                groupNames={["Search", "Reset"]}
                idsearch={"searchAffiliate"}
                idreset={"affiliateReset"}
                submit={(e) => onSearch(e)}
                reset={(e) => onResetBtn(e)}
              />
            </div>
          </div>

          <div className="mt-50"></div>
          <div className="CMS-filter-result" id="result">
            <div className="CMS-pagination">
              <Vpagination
                data={listAffiliate?.data}
                totalRecords={listAffiliate?.totalRecords}
                paginationFirstValue={pageFirstVal}
                paginationSecondValue={pageSecondVal}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={recordsToShow}
                setRecordsShow={setRecordsShow}
                isVisible={props.isVisible}
                setIsVisible={setIsVisible}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Affiliate  Report"}
                isSearch={true}
                isSearchClicked={true}
                onclickSearch={affiliateSearch}
                placeholder={"Username or Btag"}
                showSubAffiliate
                isChecked={isChecked}
                onCheckboxChange={(e) => handleCheckboxChange(e)}
              />
            </div>
            {
              !props.isVisible ?
                <Skelton vLen={5} hLen={7} />
                :
                <div className="CMS-box">
                  <div className="CMS-table CMS-table-triped">
                    <table>
                      <thead>
                        <tr>
                          {filteredHeader.map((item, index) => (
                            <th>
                              <div className='d-flex justify-content-center align-items-center'>
                                <p className='mx-3'>{item}</p>
                                <Tooltips info={affCommissionPermission === false ? `${affiliateTooltip[index]}` : `${AffiliateInfo[index]}`} />
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {props.resData &&
                          props.resData?.data &&
                          props.resData?.data !== undefined &&
                          Array.isArray(props.resData?.data) &&
                          props.resData?.data.length > 0 ? (
                          props.resData?.data.filter((item) => item.userName.toLowerCase().replace(' ', '').includes(searchData.toLowerCase().replace(' ', ''))).map((item, index) => {
                            return (

                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>
                                  <Link
                                    to={{}}
                                    onClick={() =>
                                      navigateHandler(
                                        "CREATE SUB AFFILIATE",
                                        "subaffiliate",
                                        item
                                      )
                                    }
                                  >
                                    {item.userName}
                                  </Link>
                                </td>
                                <td>{item.firstName}</td>
                                <td>{item.lastName}</td>
                                <td>{item.affiliateTypes}</td>
                                <td>{item.btag}</td>

                                <td>{item.payoutCycle}</td>

                                <td>{item.setupCostPercentage}</td>
                                {affCommissionPermission ? <td>{formatMoney(item.setupCost)}</td> : ""}
                                {affCommissionPermission ? <td>{formatMoney(item.grossCommission)}</td> : ""}
                                {affCommissionPermission ? <td > {formatMoney(item.netCommission)}</td> : ""}

                                <td>{item.commissionPercentage}</td>
                                <td>{item.totalRegistrations}</td>
                                <td>{item.depositedUserCounts}</td>
                                <td>{formatMoney(item.totalDeposit)}</td>
                                <td>{formatMoney(item.totalStake)}</td>
                                <td>{formatMoney(item.totalPayout)}</td>
                                {affGgrNgrPermission ? <td>{formatMoney(item.totalGgr)}</td> : ''}
                                {affGgrNgrPermission ? <td>{formatMoney(item.totalNgr)}</td> : ''}
                                <td>{item.totalBonus}</td>
                                <td>{item.betCount}</td>
                                <td>{formatMoney(item.totalWithdraw)}</td>
                                <td>{item.firstTimeDepositedCustomers}</td>
                                <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                                <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                                <td className="text-center">
                                  <button
                                    className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                    id="Affiliatecampaign"
                                    onClick={() =>
                                      navigateHandler("CAMPAIGN", "campaign", item)
                                    }
                                  >
                                    <p>{AFFILIATE_CONSTANTS.campaign}</p>
                                  </button>

                                  <div className="mb-10" bis_skin_checked="1"></div>

                                  <button
                                    className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                    id="Affiliatechannel"
                                    onClick={() =>
                                      navigateHandler("CHANNEL", "channel", item)
                                    }
                                  >
                                    <p>{AFFILIATE_CONSTANTS.channel}</p>
                                  </button>
                                </td>
                                <td className="text-center">
                                  {item.accountStatus === "SUSPENDED" ? (
                                    <button
                                      className={`CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusActive pointer ${viewOnlyuser ? 'disable-button' : ''}`}
                                      id="affiliateaction"
                                      onClick={() =>
                                        handleActiveDeactive(item, "ACTIVE")
                                      }
                                      disabled={viewOnlyuser}
                                    >
                                      <p>{COMMON_CONSTANTS.activate}</p>
                                    </button>
                                  ) : (
                                    <button
                                      className={`CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusClosed pointer ${viewOnlyuser ? 'disable-button' : ''}`}
                                      id="affiliateDecline"
                                      onClick={() =>
                                        handleActiveDeactive(item, "SUSPENDED")
                                      }
                                      disabled={viewOnlyuser}
                                    >
                                      <p>{COMMON_CONSTANTS.Deactivate}</p>
                                    </button>
                                  )}
                                  <div className="mb-10" bis_skin_checked="1"></div>
                                  <button
                                    className={`CMS-btn CMS-btnPrimary active vh_laign_center ${viewOnlyuser ? 'disable-button' : ''}`}
                                    id="affiliateEdit"
                                    onClick={() => handleDialog(item)}
                                    disabled={viewOnlyuser}
                                  >
                                    <span className="material-icons edit">
                                      {COMMON_CONSTANTS.span_editIcon}
                                    </span>
                                    &nbsp;{COMMON_CONSTANTS.edit}
                                  </button>
                                </td>
                                <td>{item.affliateClickCount}</td>
                                {props.permissionToEditUserDetails &&
                                  <td>
                                    <button
                                      className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
                                      onClick={() => handleEditUser(item)}
                                      id='EditUserBtn'
                                    >
                                      {MANUALDIALOG_CONSTANTS.edit_user}
                                    </button>
                                  </td>}
                                {afiilatePay && <td>
                                  <button
                                    className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}

                                    onClick={() => handlePay(item.phoneNumber)}
                                    id='payBtn'
                                  >
                                    {MANUALDIALOG_CONSTANTS.pay}
                                  </button>
                                </td>}
                              </tr>
                            );
                          })
                        ) : (
                          <No__Data__Found colspan={22} />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
            }
          </div>
        </div>
      </div >
    </>
  );
};

function mapStateToProps(state) {
  return {
    paginationFirstValue: state.affiliateSlice.paginationFirstValue,
    paginationSecondValue: state.affiliateSlice.paginationSecondValue,
    resData: state.affiliateSlice.resData,
    openDialog: state.affiliateSlice.openDialog,
    isVisible: state.affiliateSlice.isVisible,
    docsData_aff: state.affiliateSlice.docsData_aff,
    formData_aff: state.affiliateSlice.formData_aff,
    formErrors_aff: state.affiliateSlice.formErrors_aff,
    userRole: state.loginSlice.userRole,
    affiliateSearchData: state.affiliateSlice.affiliateSearchData,
    permissionToEditUserDetails: state.dashboard.permissionToEditUserDetails,
    affArtWork: state.dashboard.affArtWork,
    disableBtn: state.affiliateSlice.disableBtn
  };
}

export default connect(mapStateToProps)(Affiliate);
